import * as React from 'react';
import './LineField.scss';
import randomString from 'crypto-random-string';

export interface ILineFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
}

export default function LineField ({ id, ...props}: ILineFieldProps) {
  const sharedId = id || randomString({ length: 10 });
  return (
    <div className="line-field">
      <input id={sharedId} {...props} />
    </div>
  );
}
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import * as Icon from 'react-icons/ai';
import Parse, { deleteFile } from '../../utils/parse';
import RoundedButton from '../buttons/RoundedButton';
import Margin from '../common/Margin';
import Spinner from '../Spinner';
import './Photos.scss';

const maxPhoto = 4;

export interface IPhotosProps {
  onPhotosUploaded: (photos: Parse.File[]) => any;
}

export default function Photos ({ onPhotosUploaded }: IPhotosProps) {
  const { t } = useTranslation();
  const [_photos, set_photos] = React.useState<Parse.File[]>([]);
  const [_error, set_error] = React.useState<any>(null);
  const [_loading, set_loading] = React.useState(false);
  const fileInpurRef: React.RefObject<HTMLInputElement> = React.useRef(null);

  const onFilesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    set_loading(true);
    set_error(null);
    const filesData = e.target.files || [];
    const files: Parse.File[] = [];
    for (let i = 0; i < filesData.length; i++) {
      if(_photos.length + i === maxPhoto){
        break;
      }

      const fileData = filesData[i];
      const file = new Parse.File(fileData.name, fileData);
      files.push(file);
    }
    Promise.all(files.map(file => file.save()))
    .then((fileResults) => {
      const allPhotos = [...fileResults, ..._photos];
      set_photos([...allPhotos]);
      onPhotosUploaded([...allPhotos]);
    })
    .catch((err) => set_error(err.message))
    .finally(() => set_loading(false));
  };

  const handleClick =  () => fileInpurRef.current?.click();

  const onDelete = (photo: Parse.File) => {
    set_loading(true);
    deleteFile(photo)
    .then(() => set_photos([..._photos.filter(p => p.url() !== photo.url())]))
    .catch((err) => set_error(err.message))
    .finally(() => set_loading(false));
  };

  return (
    <>
    <label className="mb-4" htmlFor="photo-input">
      {t('request.add_photo_label')}
    </label>
      {
        _photos.length < maxPhoto &&
        <RoundedButton className="mb-4" disabled={_loading} onClick={handleClick}>
          {_loading && <Spinner secondary />}
          {t('shared.add_photo', { count: maxPhoto - _photos.length })}
        </RoundedButton>
      }
      <div className="photos d-flex flex-wrap">
        <input name="photo-input" id="photo-input" multiple max={maxPhoto} onChange={onFilesChange} ref={fileInpurRef} type="file" hidden />
        {
          _photos.map((photo, idx) => {
            return (
              <div onClick={() => onDelete(photo)} key={`photo-${idx}`} className="photo ml-2 mb-2">
                <div className="delete-photo"></div>
                <img src={photo.url()} alt={photo.name()} />
              </div>
            );
          })
        }
      </div>
      {
        _error !== null &&
        <div className="photo-errors">
          <p>{_error}</p>
        </div>
      }
    </>
  );
}

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, RouteChildrenProps, withRouter } from 'react-router-dom';
import { AiOutlineMenu } from 'react-icons/ai';
import { useForceUpdate } from '../utils/hooks';
import Parse from '../utils/parse';
import AccountMenu from './AccountMenu';
import RoundedButton from './buttons/RoundedButton';
import Logo from './Logo';
import defaultAvatar from '../images/default-avatar.png';
import './Navbar.scss';
import Locales from './AccountMenu/Locales';
import colors from '../styles/variables';
import Margin from './common/Margin';
import { frontendUrl, isProd, isStaging } from '../utils/env';

export interface INavbarProps extends RouteChildrenProps {}

function Navbar({ history }: INavbarProps) {
  const { t, i18n } = useTranslation();
  const { data } = i18n.store;
  const forceUpdate = useForceUpdate();
  const [_menuIsOpen, set_menuIsOpen] = React.useState(false);
  const [_localesMenuIsOpen, set_localesMenuIsOpen] = React.useState(false);
  const [_mobileMenuIsOpen, set_mobileMenuIsOpen] = React.useState(false);
  const currentUser = Parse.User.current();
  const isConnected = Boolean(currentUser);

  const CustomLink = ({ link }: { link: 'services' | 'contact' }) => {
    if(isProd || isStaging){
      if(link === 'services'){
        return <a href={`${frontendUrl}/services`}>{t('navbar.services')}</a>;
      }
      if(link === 'contact'){
        return <a href={`${frontendUrl}/contact`}>{t('navbar.contact')}</a>;
      }
    } else {
      if(link === 'services'){
        return <Link to="/services">{t('navbar.services')}</Link>;
      }
      if(link === 'contact'){
        return <Link to="/contact">{t('navbar.contact')}</Link>;
      }
    }

    return <></>;
  };

  const Links = () => {
    return (
      <nav className="d-flex align-items-center">
        <ul>
          <li className="mr-5">
            <CustomLink link="services" />
          </li>
          <li>
            <CustomLink link="contact" />
          </li>
        </ul>
      </nav>
    );
  };

  const LocalesContainer = () => {
    return (
      <span style={{ position: 'relative' }} className="locales mr-3">
        <span
          style={{ cursor: 'pointer', fontWeight: 700, color: colors.primary }}
          onClick={() => set_localesMenuIsOpen(true)}
        >
          {
            //@ts-ignore
            i18n.store.data[i18n.language].translation.language
          }
        </span>
        <Locales
          isOpen={_localesMenuIsOpen}
          toggle={() => set_localesMenuIsOpen(false)}
        />
      </span>
    );
  };

  const AccountMenuContainer = () => {
    if(!currentUser) return null;

    return (
      <span style={{ position: 'relative' }} className="mr-3">
        <img
          className="user-avatar"
          alt={currentUser.attributes.username}
          src={currentUser.attributes.profilePicture?.url() || defaultAvatar}
          onClick={() => set_menuIsOpen(true)}
        />
        <AccountMenu
          isOpen={_menuIsOpen}
          toggle={() => set_menuIsOpen(false)}
        />
      </span>
    );
  };

  const SigninButton = () => {
    return (
      <RoundedButton
        primary
        type="button"
        onClick={() => {
          if (isConnected) {
            Parse.User.logOut().then(() => {
              forceUpdate();
              history.push('/');
            });
          } else {
            history.push('/signin');
          }
        }}
      >
        {isConnected ? t('shared.signout') : t('signin.title')}
      </RoundedButton>
    );
  };

  const SignupButton = () => {
    if(currentUser) return null;

    return (
      <RoundedButton className="mr-3" secondary onClick={() => history.push('/signup')}>
        {t('signup.title')}
      </RoundedButton>
    );
  };

  return (
    <>
      <div className="navbar desktop d-flex align-items-center">
        <div className="navbar-logo">
          <a href="https://gorush.ca">
            <Logo secondary width="48px" height="56px" />
          </a>
        </div>
        <div
          style={{ width: '100%' }}
          className="d-flex justify-content-between"
        >
          <Links />
          <div className="mr-5 d-flex align-items-center">
            <LocalesContainer />
            <AccountMenuContainer />
            <SignupButton />
            <SigninButton />
          </div>
        </div>
      </div>

      <div onClick={() => _mobileMenuIsOpen && set_mobileMenuIsOpen(false)} className={`navbar mobile d-flex align-items-center ${_mobileMenuIsOpen ? 'open' : 'close'}`}>
        <div className="navbar-logo">
          <a href="https://gorush.ca">
            <Logo secondary width="47px" height="55px" />
          </a>
        </div>
        <div
          style={{ width: '100%' }}
          className="d-flex justify-content-between"
        >
          <div
            style={{ width: '100%' }}
            className={`mr-5 d-flex justify-content-between align-items-center mobile-items ${_mobileMenuIsOpen ? 'open' : 'close'}`}
          >
            <div className="mobile-left">
              <Links />
              <ul>
                {
                  Object.keys(data).map((language) => {
                    return (
                      <li key={language}>
                        <span
                          style={{ cursor: 'pointer' }}
                          onClick={() => i18n.changeLanguage(language)}
                        >
                        {
                          //@ts-ignore
                          data[language].translation.language
                        }
                        </span>
                      </li>
                    );
                  })
                }
              </ul>
              <ul>

              </ul>
            </div>
            <div className="mobile-right d-flex flex-column align-items-end pr-2">
              {
                currentUser &&
                <ul className="mr-3">
                  <li>
                    <Link to="/">{t('navbar.account_menu.my_requests')}</Link>
                  </li>
                  <li>
                    <Link to="/my-account">{t('navbar.account_menu.my_account')}</Link>
                  </li>
                </ul>
              }
              {
                !currentUser &&
                <>
                  <SignupButton />
                  <Margin />
                </>
              }
              <SigninButton />
            </div>
          </div>
        </div>
        <span
          onClick={() => set_mobileMenuIsOpen(!_mobileMenuIsOpen)}
          className={`mobile-toggler ${_mobileMenuIsOpen ? 'open' : 'close'}`}
        >
          <AiOutlineMenu size="30px" />
        </span>
      </div>
    </>
  );
}

export default withRouter(Navbar);
import * as React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { Link, Redirect } from 'react-router-dom';
import RoundedButton from '../../components/buttons/RoundedButton';
import Margin from '../../components/common/Margin';
import SquareField from '../../components/inputs/SuareField';
import Logo from '../../components/Logo';
import Spinner from '../../components/Spinner';
import Title from '../../components/Title';
import { useForceUpdate } from '../../utils/hooks';
import Parse from '../../utils/parse';

export interface ISignupProps {
  modal?: boolean;
  toggleType?: () => any;
  onSuccess?: () => any;
}

export default function Signup ({ modal = false, toggleType = () => {}, onSuccess = () => {} }: ISignupProps) {
  const [_loading, set_loading] = React.useState(false);
  const forceUpdate = useForceUpdate();
  const { t } = useTranslation();
  const currentUser = Parse.User.current();
  const isConnected = Boolean(currentUser);
  const signup = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    set_loading(true);
    //@ts-ignore
    const form = new FormData(e.target);
    const email = form.get('email');
    const firstName = form.get('firstName');
    const lastName = form.get('lastName');
    const password = form.get('password');
    if(typeof password === 'string' && typeof email === 'string'){
      Parse.User.signUp(email, password, { firstName, lastName, email })
      .then(() => { 
        forceUpdate();
        onSuccess();
      })
      .catch((error) => {
        confirmAlert({
          title: t('shared.error'),
          message: error.message,
          buttons: [
            {
              label: 'Ok',
              onClick: () => {},
              className: 'alert-confirm-button'
            }
          ]
        });
      })
      .finally(() => set_loading(false));
    }
  };

  if(isConnected && !modal){
    return <Redirect to="/" />;
  }

  return (
    <form style={{ height: modal ? 'auto' : '90vh' }} onSubmit={signup} className="d-flex flex-column align-items-center justify-content-center">
      <div>
        <Logo width="114px" />
      </div>
      <div>
        <Title secondary>{t('signin.create_account')}</Title>
      </div>
      <Margin lg/>
      <div>
        <SquareField name="firstName" placeholder={t('shared.firstname')} />
      </div>
      <Margin />
      <div>
        <SquareField name="lastName" placeholder={t('shared.lastname')} />
      </div>
      <Margin />
      <div>
        <SquareField name="email" placeholder={t('signin.email')} />
      </div>
      <Margin />
      <div>
        <SquareField type="password" name="password" placeholder={t('shared.password')} />
      </div>
      <Margin />
      <div>
        <SquareField type="phone" name="phone" placeholder={t('shared.phone')} />
      </div>
      <Margin xl />
      <div>
        <RoundedButton type="submit">
          {
            _loading &&
            <Spinner size="18px" secondary />
          }
          {t('signup.action')}
        </RoundedButton>
      </div>
      <div>
        <p className="info-text">
          {t('signup.have_acount')}{' '}
          {
            modal
            ? <a onClick={(e) => { e.preventDefault(); toggleType(); }} href="/signin">{t('signin.title')}</a>
            : <Link to="signin">{t('signin.title')}</Link>
          }
        </p>
      </div>
    </form>
  );
}

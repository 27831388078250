import * as React from 'react';
import { useTranslation } from 'react-i18next';
import colors from '../../styles/variables';
import Request from '../../utils/request';

function pad_with_zeroes(number: number) {

  var my_string = '' + number;
  while (my_string.length < 2) {
      my_string = '0' + my_string;
  }

  return my_string;

}

export interface ITimeStateProps {
  request: Request;
}

export function TimeState ({ request }: ITimeStateProps) {
  const { timeLimit } = request.attributes;

  const date = new Date(timeLimit || '');
  const calculateTimeLeft = () => {
    //@ts-ignore
    let difference = Math.abs(new Date() - date);
    let timeLeft = {
      hours: 0,
      minutes: 0,
      seconds: 0
    };

    if (difference > 0) {
      timeLeft = {
        hours: Math.floor((difference / (1000 * 60 * 60))),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = React.useState(calculateTimeLeft());
  const { t } = useTranslation();

  React.useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  if(!timeLimit) return null;
  if(date < new Date()) return null;

  return (
    <span className="d-flex">
      <h2>
        {t('request.time_limit')}
      </h2>
      <h2 style={{ color: colors.primary }} className="ml-2">
        {pad_with_zeroes(timeLeft.hours)}h {pad_with_zeroes(timeLeft.minutes)}min {pad_with_zeroes(timeLeft.seconds)}s
      </h2>
    </span>
  );
}

export interface paramsT {
  service?: string;
  locale?: string;
  first_view?: string;
}
export const params = () => {
  const search = window.location.search.substring(1);
  const params: paramsT = search ? JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}') : {};
  return params;
};

export const cleanObject = (obj: any) => {
  for (var propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined || (typeof obj[propName] === 'string' && obj[propName].length < 1)) {
      delete obj[propName];
    }
  }
  return obj;
};
import * as React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import colors from '../styles/variables';

export interface ISpinnerProps {
  primary?: boolean;
  secondary?: boolean;
  [key: string]: any;
}

export default function Spinner ({ primary = true, secondary = false, ...props}: ISpinnerProps) {
  const customProps: any = {
    ...props
  };
  if(primary){
    customProps.color = colors.primary;
  }
  if(secondary){
    customProps.color = 'white';
  }
  return (
    <span className="m-2">
      <ClipLoader {...customProps} />
    </span>
  );
}

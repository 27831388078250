import * as React from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import RoundedButton from '../../components/buttons/RoundedButton';
import { mapState } from '../../declarations/map';
import colors from '../../styles/variables';
import { isTest } from '../../utils/env';
import { customShape, drawingManagerOptions, formatedZone, googleMapApi, isLessThanMeter, polygonsFromArea, toGmapPoint } from '../../utils/googleMap';
import { useForceUpdate } from '../../utils/hooks';
import { customModalStyles } from '../../utils/modals';
import Parse from '../../utils/parse';
import './Map.scss';

if(!isTest){
  Modal.setAppElement('#root');
}

let map: google.maps.Map;
let centeredMarked: google.maps.Marker;
const centerHistory = [];
const selectedColor = colors.primary;
const defaultColor = colors.secondary;

let selected: customShape | null = null;
let zones: customShape[] = [];

export interface IMapProps {
  mapState: mapState;
  onValid: (zones: formatedZone[]) => any;
  mapAreaMode: boolean;
  serviceType?: 'housesnowremoval' | 'lawnmower' | 'leafpickups' | 'carsnowremoval' | 'aeration' | 'dethatching';
}

function Map ({ mapState, mapAreaMode = true, serviceType, ...rest }: IMapProps) {
  const forceUpdate = useForceUpdate();
  const { t } = useTranslation();
  const [_modalIsOpen, set_modalIsOpen] = React.useState(mapAreaMode);
  const [_areaModeStep, set_areaModeStep] = React.useState<null | 1 | 2>(1);
  const [_areasConfirmed, set_areasConfirmed] = React.useState(false);

  const setMarker = () => {
    // mapState.center is set from automplete address input
    if(map){
      const center = new googleMapApi.maps.LatLng(mapState.center.lat, mapState.center.lng);
      map.panTo(center);
      map.setMapTypeId(mapState.mapTypeId || 'satellite');

      if(centeredMarked){
        centeredMarked.setMap(null);
      }

      centeredMarked = new google.maps.Marker({
        position: mapState.center,
        map,
        title: mapState.center.description,
      });

      forceUpdate();
    }
  };

  const onNewShape = (newShape: any) => {
    newShape.id = Date.now();
    googleMapApi.maps.event.addListener(newShape, 'click', function() {
      setSelection(newShape);
    });

    if(newShape.type === 'polygon'){
      setSelection(newShape);
      zones.push(newShape);
      if(zones.length === 1){
        const nextStep = _areaModeStep === 1 ? 2 : null;
        set_areaModeStep(nextStep);
        if(nextStep) set_modalIsOpen(true);
      }
      set_areasConfirmed(false);
    }
    forceUpdate();
  };

  React.useEffect(() => {
    // Init map
    map = new googleMapApi.maps.Map(document.getElementById('map') as HTMLElement, {
      center: mapState.center,
      zoom: mapState.zoom,
      tilt: 0,
      rotateControl: false
    });

    if(mapAreaMode){
      // Init drawing manager
      const drawingManager = new googleMapApi.maps.drawing.DrawingManager(drawingManagerOptions);
      // Set drawing manager on the map
      drawingManager.setMap(map);

      // Listen shape creation
      googleMapApi.maps.event.addListener(drawingManager, 'overlaycomplete', function(event: any) {
        const newShape: customShape = event.overlay;
        newShape.type = event.type;
        onNewShape(newShape);
      });
    }
  }, []);

  React.useEffect(() => {
    // Set tutorial for the first map loading
    setMarker();
    centerHistory.push(mapState.center);
    // Clear current selection if address changes
    zones.map((zone) => {
      selected = zone;
      onDelete();
    });
    zones = [];
    onValid();
    if(serviceType){
      const user = Parse.User.current();
      if(user){
        const mapSearchCenter = toGmapPoint(mapState.center);
        let userAreas: any[] = [];
        switch(serviceType){
          case 'lawnmower':
            if(Array.isArray(user.attributes.mowing2)){
              userAreas = user.attributes.mowing2;
            }
            break;
          case 'leafpickups':
            if(Array.isArray(user.attributes.picking2)){
              userAreas = user.attributes.picking2;
            }
            break;
          case 'carsnowremoval':
            if(Array.isArray(user.attributes.carSnowing2)){
              userAreas = user.attributes.carSnowing2;
            }
            break;
          case 'housesnowremoval':
            if(Array.isArray(user.attributes.snowing2)){
              userAreas = user.attributes.snowing2;
            }
            break;
          case 'aeration':
            if(Array.isArray(user.attributes.aeration)){
              userAreas = user.attributes.aeration;
            }
            break;
          case 'dethatching':
            if(Array.isArray(user.attributes.dethatching)){
              userAreas = user.attributes.dethatching;
            }
            break;
          default:
            break;
        }
        userAreas
        // Skip old objects
        .filter((userArea) => Object.keys(userArea).every((key) => ['center', 'areaArray', 'totalSurface'].includes(key)))
        // Transform center coord to gmap point
        .map((userArea => {
          return {
            gmapPoint: toGmapPoint({ lat: userArea.center[0], lng: userArea.center[1] }),
            ...userArea
          };
        }))
        // Keep only area with less than 30m oof distance of current point
        .filter((userArea) => isLessThanMeter(userArea.gmapPoint, mapSearchCenter, 30))
        // Transform area to gmap shape
        .map((userArea) => polygonsFromArea(userArea.areaArray, onNewShape))
        // Display polygon to map
        .forEach((gmapPolygons) => {
          gmapPolygons.forEach((polygon) => {
            polygon.setMap(map);
          });
          set_modalIsOpen(false);
        });
      }
    }
  }, [mapState.center]);

  const clearSelection = () => {
    if (selected) {
      selected.setEditable(false);
      selected.set('fillColor', defaultColor);
      selected = null;
      forceUpdate();
    }
  };

  const setSelection = (shape: customShape) => {
    if(shape.type === 'marker'){
      return shape.setMap(null);  ;
    }
    clearSelection();
    shape.setEditable(true);
    shape.set('fillColor', selectedColor);
    selected = shape;
    selected.getPaths().forEach(function(path, index){
      google.maps.event.addListener(path, 'insert_at', function(){
        set_areasConfirmed(false);
      });
      google.maps.event.addListener(path, 'set_at', function(){
        set_areasConfirmed(false);
      });
    });
    forceUpdate();
  };
  
  const onDelete = () => {
    if(selected && selected !== null){
      //@ts-ignore
      zones = [...zones.filter((z) => z.id !== selected.id)];
      selected.setMap(null);
      selected = null;
      onValid();
    }
  };

  const onValid = () => {
    //@ts-ignore
    const formatedZones: formatedZone[] = zones.map((zone) => {
      // Currently only polygon are allowed
      if (zone.type === 'polygon') {
        const path = zone.getPath().getArray().map(({lat, lng}: any) => ([lat(), lng()]));
        const surface = googleMapApi.maps.geometry.spherical.computeArea(zone.getPath());
        // convert m2 to feet2
        return { type: zone.type, path, surface: (surface * 3.28084).toFixed(2) };
      }
    }).filter((formatedZone) => formatedZone !== undefined);
    clearSelection();
    rest.onValid(formatedZones);
    set_areasConfirmed(true);
  };
  
  return (
    <div style={{ position: 'relative' }}>
      {
        !_modalIsOpen &&
        <div className="custom-map-toolbox d-flex">
          {
            selected && 
            <>
              <RoundedButton secondary onClick={onDelete}>
                {t('map.remove_selection')}
              </RoundedButton>
            </>
          }
          {
            zones.length >= 1 && !_areasConfirmed &&
            <RoundedButton onClick={onValid}>
              {
                t('map.confirm_area', { count: zones.length })
              }
            </RoundedButton>
          }
        </div>
      }
      <div style={{ width: '100%', height: '500px' }} id="map" />
        <Modal
          isOpen={_modalIsOpen}
          onRequestClose={() => set_modalIsOpen(false)}
          style={customModalStyles}
        >
          <Grid fluid>
            <Row>
              <Col style={{ width: '100%' }}>
                <h1>
                  {t(`map.tutorial.${serviceType}.step_${_areaModeStep}.title`)}
                </h1>
                  {
                    t(`map.tutorial.${serviceType}.step_${_areaModeStep}.content`).split('<br/>').map(line => <p>{line}</p>)
                  }
              </Col>
            </Row>
            <Row>
              <Col style={{ width: '100%' }} className="d-flex justify-content-center">
                <RoundedButton onClick={() => set_modalIsOpen(false)}>
                  {t('map.tutorial.confirm')}
                </RoundedButton>
              </Col>
            </Row>
          </Grid>
      </Modal>
    </div>
  );
}

export default Map;

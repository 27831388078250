import * as React from 'react';
import { images, useCardBrand } from 'react-card-brand';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { AiFillDelete, AiOutlineCheck } from 'react-icons/ai';
import { card, stripeCustomerAccount } from '../../declarations/paymentMethods.d';
import colors from '../../styles/variables';
import Parse, { getPaymentMethods, setDefaultPaymentMethodCustomer, deletePaymentMethodCustomer } from '../../utils/parse';
import StripeCustomer from '../../utils/stripeCustomer';
import Spinner from '../Spinner';
import './List.scss';


export interface IListProps {
  newCardAdded?: number;
  onSelectCard?: (card: card) => any;
  selectable?: boolean;
}

export default function List ({ newCardAdded, onSelectCard = () => {}, selectable = false }: IListProps) {
  const { getSvgProps } = useCardBrand();
  const { t } = useTranslation();
  const [_loading, set_loading] = React.useState(false);
  const [_stripeCustomer, set_stripeCustomer] = React.useState<stripeCustomerAccount>();
  const currentUser = Parse.User.current();
  const cards: card[] = _stripeCustomer?.sources?.data || [];

  const fetchPaymentMethods = () => {
    set_loading(true);
    if(currentUser){
      StripeCustomer.me()
      .then((stripeCustomer) => {
        getPaymentMethods(stripeCustomer?.attributes?.idStripe)
        .then((customerAccount: stripeCustomerAccount) => {
          set_stripeCustomer(customerAccount);
        })
        .finally(() => set_loading(false));
      });
    }
  };

  const setDefaultPaymentMethod = (cardId: string) => {
    set_loading(true);
    if(currentUser){
      return StripeCustomer.me()
      .then((stripeCustomer) => {
        setDefaultPaymentMethodCustomer(stripeCustomer?.attributes?.idStripe, cardId)
        .then(fetchPaymentMethods)
        .finally(() => set_loading(false));
      });
    }
    return Promise.resolve();
  };

  const deletePaymentMethod = (cardId: string) => {
    set_loading(true);
    if(currentUser){
      StripeCustomer.me()
      .then((stripeCustomer) => {
        deletePaymentMethodCustomer(stripeCustomer?.attributes?.idStripe, cardId)
        .then(fetchPaymentMethods)
        .finally(() => set_loading(false));
      });
    }
  };

  React.useEffect(() => {
    fetchPaymentMethods();
  }, [newCardAdded]);

  return (
    <div>
      {
        _loading &&
        <div className="d-flex justify-content-center align-items-center">
          <Spinner />
        </div>
      }
      <ul className="payment-methods-list">
        {
          cards.map((card) => {
            return (
              <li
                key={card.id}
                className={`d-flex align-items-center justify-content-between ${selectable ? 'selectable' : ''}`}
                onClick={async () => {
                  if(selectable){
                    await setDefaultPaymentMethod(card.id);
                    onSelectCard(card);
                  }
                }}
              >
                <span className="d-flex align-items-center mr-2 card-info">
                  <svg {...getSvgProps({ type: card.brand?.toLowerCase(), images })} />
                  <span className="ml-2">.... {card.last4}</span>
                </span>
                <span className="card-actions">
                {
                  card.id === _stripeCustomer?.default_source
                  ? <AiOutlineCheck color={colors.green} />
                  : (
                    !selectable &&
                    <>
                      <AiOutlineCheck
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setDefaultPaymentMethod(card.id);
                        }}
                        style={{ cursor: 'pointer' }}
                      />
                      <AiFillDelete
                        color="red"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          confirmAlert({
                            title: t('shared.delete'),
                            message: t('shared.confirm_delete_item', { item: `card ****${card.last4}` }),
                            buttons: [
                              {
                                label: t('shared.yes'),
                                className: 'alert-confirm-button',
                                onClick: () => {
                                  deletePaymentMethod(card.id);
                                }
                              },
                              {
                                label: t('shared.no'),
                                className: 'alert-cancel-button',
                                onClick: () => {}
                              }
                            ]
                          });
                        }}
                        style={{ cursor: 'pointer' }}
                      />
                    </>
                  )
                }
                </span>
              </li>
            );
          })
        }
      </ul>
    </div>
  );
}

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useOutsideClick } from '../../utils/hooks';
import './index.scss';

export interface IAccountMenuProps {
  isOpen: boolean;
  toggle: any;
}

export default function AccountMenu ({ isOpen, toggle }: IAccountMenuProps) {
  const { t } = useTranslation();
  const ref = React.useRef(null);
  useOutsideClick(ref, toggle);
  const classNames = `account-menu ${isOpen ? 'menu-open' : 'menu-close'}`;
  return (
    <div ref={ref} className={classNames}>
      <div className="account-menu-item">
        <Link onClick={toggle} to="/">{t('navbar.account_menu.my_requests')}</Link>
      </div>
      <div className="account-menu-item">
        <Link onClick={toggle} to="/my-account">{t('navbar.account_menu.my_account')}</Link>
      </div>
    </div>
  );
}

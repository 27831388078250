import * as React from 'react';
import Modal from 'react-modal';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import ReactStars from 'react-rating-stars-component';
import RoundedButton from '../../components/buttons/RoundedButton';
import { useForceUpdate } from '../../utils/hooks';
import Parse from '../../utils/parse';
import Request from '../../utils/request';
import Review from '../../utils/review';
import { customModalStyles } from '../../utils/modals';
import { Col, Grid, Row } from 'react-flexbox-grid';
import SquareField from '../../components/inputs/SuareField';
import Margin from '../../components/common/Margin';
import Services from '../../utils/service';

export interface IStateButtonProps {
  request: Request;
  service?: Parse.Object<Services>;
  mode: 'edit' | 'create';
  loading: boolean;
  setLoading: (state: boolean) => any;
}

export default function StateButton ({ request, service, mode, loading, setLoading }: IStateButtonProps) {
  const { t } = useTranslation();
  const forceupdate = useForceUpdate();
  const [_review, set_review] = React.useState<Review>();
  const [_reviewText, set_reviewText] = React.useState('');
  const [_rate, set_rate] = React.useState(0);
  const [_modalReviewOpen, set_modalReviewOpen] = React.useState(false);
  const toggleReviewModal = () => set_modalReviewOpen(!_modalReviewOpen);

  React.useEffect(() => {
    fetchReview();
  }, [request?.id]);

  const fetchReview = () => {
    if(request.id){
      console.log(request.id);
      Review.findBy('requestId', request.id)
      .then((review) => {
        set_review(review);
        set_rate(review?.attributes?.rate || 5);
        set_reviewText(review?.attributes?.review);
      });
    }
  };

  const cancelRequest = () => {
    let message = '';
    if(request.attributes.state === 'pending'){
      message = t('request.cancel.pending.body');
    }

    if(request.attributes.state === 'accepted'){
      const priceCustomer = request?.get('priceCustomer');
      const cancellationFee = service?.get('cancellationFee');
      const charge = ((cancellationFee / 100) * priceCustomer).toFixed(2);
      message = t('request.cancel.accepted.body', { charge, cancellationFee });
    }

    const cancel = () => {
      setLoading(true);
      request.set('state', 'canceled');
      request.save()
      .then((response: Request) => {
        request = response;
        forceupdate();
      })
      .finally(() => setLoading(false));
    };

    confirmAlert({
      title: t('request.cancel.title'),
      message,
      buttons: [
        {
          label: t('shared.no'),
          onClick: () => {},
          className: 'alert-cancel-button'
        },
        {
          label: t('shared.yes'),
          className: 'alert-confirm-button',
          onClick: () => {
            setLoading(true);
            if(request.attributes.state === 'pending'){
              return cancel();
            }
            if(request.attributes.state === 'accepted'){
              return  Parse.Cloud
                      .run('CancelChargeAndApplyCancellationFee', { requestId: request.id })
                      .then(cancel)
                      .finally(() => setLoading(false));
            }
          }
        }
      ]
    });
  };

  const sendReview = () => {
    if(Boolean(_review)){
      return toggleReviewModal();
    }
    setLoading(true);
    const review = new Review();
    review.set('rate', _rate);
    review.set('available', true);
    review.set('review', _reviewText);
    review.set('request', request);
    review.set('requestId', request.id);
    review.set('from', 'customer');
    review.set('workerId', request.attributes.workerId);
    review.set('customerId', Parse.User.current()?.id);
    review.set('worker', request.attributes.worker);
    review.set('customer', request.attributes.customer);

    review.save()
    .then((response: any) => { console.log(response); })
    .catch((error: any) => { console.log(error); })
    .finally(() => {
      setLoading(false);
      toggleReviewModal();
      fetchReview();
    });
  };

  const label = () => {
    if(request.attributes.state === 'ended' && Boolean(_review)){
      return t('request.state.show_review');
    }
    return t(`request.state.${request.attributes.state}`);
  };

  const isDisabled = () => {
    if(request.attributes.state === 'canceled'){
      return true;
    }
  };

  const runAction = () => {
    // La requete est en attente
    if(request.attributes.state === 'pending'){
      return cancelRequest();
    }
    // La requete est en accepté
    if(request.attributes.state === 'accepted'){
      return cancelRequest();
    }
    // Le travail est terminé
    if(request.attributes.state === 'ended'){
      return toggleReviewModal();
    }
  };

  console.log({_review});

  return (
    <>
      <RoundedButton disabled={isDisabled()} onClick={runAction}>
        {label()}
      </RoundedButton>
      <Modal
        isOpen={_modalReviewOpen}
        onRequestClose={toggleReviewModal}
        style={customModalStyles}
      >
        <Grid>
          <Row>
            <Col xs={12} className="d-flex justify-content-center">
              <ReactStars
                edit={!Boolean(_review)}
                count={5}
                value={_rate}
                onChange={(rate: number) => set_rate(rate)}
                size={24}
                activeColor="#ffd700"
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col xs={12}>
              <SquareField
                label={t('request.review_label')}
                style={{ maxWidth: '100%' }}
                className="field-md"
                type="textarea"
                multiple
                required
                disabled={Boolean(_review)}
                value={_reviewText}
                onChange={(e) => set_reviewText(e.target.value)}
              />
              <Margin />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col xs={12} className="d-flex justify-content-center">
              <RoundedButton onClick={sendReview}>
                {
                  !Boolean(_review) &&
                  t('request.review_action')
                }
                {
                  Boolean(_review) &&
                  t('shared.close')
                }
              </RoundedButton>
            </Col>
          </Row>
        </Grid>
      </Modal>
    </>
  );
}

import * as React from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { useTranslation } from 'react-i18next';
import RoundedButton from '../../components/buttons/RoundedButton';
import Margin from '../../components/common/Margin';
import Spinner from '../../components/Spinner';
import RequestCard from '../../components/static/RequestCard';
import Title from '../../components/Title';
import { frontendUrl, isProd, isStaging } from '../../utils/env';
import Parse from '../../utils/parse';
import Request from '../../utils/request';
import { Redirect } from "react-router-dom";

export interface IMyRequestsProps {}

export default function MyRequests (props: IMyRequestsProps) {
  const { t } = useTranslation();
  const [_requests, set_requests] = React.useState<Request[]>([]);
  const [_loading, set_loading] = React.useState(true);
  const [_redirectSignIn, set_redirectSignIn] = React.useState(false);
  React.useEffect(() => {
    const currentUser = Parse.User.current();
    if(currentUser){
      Request.where({ customer: currentUser })
      //@ts-ignore
      .then((requests) => set_requests([...requests.sort((a, b) => (new Date(b.attributes.createdAt) - new Date(a.attributes.createdAt)))]))
      .finally(() => set_loading(false));
    } else {
      set_redirectSignIn(true);
    }
  }, []);

  const linkAction = () => {
    if(isProd || isStaging){
      return window.location.href = `${frontendUrl}/services`;
    } else {
      //@ts-ignore
      return props.history.push('/services');
    }
  };

  if (_redirectSignIn) {
    return <Redirect to="/signin" push/>
  }
  return (
    <Grid fluid style={{ padding: '0 5vw' }}>
      <Row>
        <Col xs={12}>
          <Margin lg />
          <Title style={{ textAlign: 'center' }} secondary>
            {t('my_requests.title')}
          </Title>
          <Margin lg />
        </Col>
      </Row>
      <Row>
        {
          _loading &&
          <div style={{ width: '100%', height: '50vh' }} className="d-flex justify-content-center align-items-center">
            <Spinner primary />
          </div>
        }
        {
          (_requests.length < 1 && !_loading) &&
          <h2 style={{ width: '100%', textAlign: 'center'  }}>{t('my_requests.not_found')}</h2>
        }
        {
          _requests.map((request) => {
            return (
              <Col key={request.id} xs={12} sm={6} lg={4} className="mb-5 d-flex justify-content-center">
                <RequestCard
                  //@ts-ignore
                  request={request}
                />
              </Col>
            );
          })
        }
      </Row>
      {
        !_loading &&
        <Row>
          <Col xs={12} className="d-flex justify-content-center mb-5">
            <RoundedButton breakable onClick={linkAction}>
              {t('my_requests.add_new_service')}
            </RoundedButton>
          </Col>
        </Row>
      }
    </Grid>
  );
}

import Parse from './parse';

class Review extends Parse.Object.extend('Review') {
  static apiName = 'Review';
  static getAll(opt = { limit: 25 }) {
    const query = new Parse.Query(this.apiName);
    query.limit(opt.limit);
    return query.find();
  }

  static find(id: string | number) {
    const query = new Parse.Query(this.apiName);
    query.equalTo('objectId', id);
    return query.first();
  }

  static findBy(key: string, value: any) {
    const query = new Parse.Query(this.apiName);
    query.equalTo(key, value);
    return query.first();
  }
}

export default Review;

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import frCA from './locales/fr-CA.json';
import enEN from './locales/en-EN.json';
import { params } from './utils/params';

const localeParams = params().locale || navigator.language;
let locale;
if(['en', 'en-en', 'en-us'].includes(localeParams.toLowerCase())){
  locale = 'en-EN';
}
if(['fr', 'fr-fr', 'fr-ca'].includes(localeParams.toLowerCase())){
  locale = 'fr-CA';
}

const resources = {
  'fr-CA': {
    translation: {
      ...frCA
    }
  },
  'en-EN': {
    translation: {
      ...enEN
    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: locale,
    interpolation: {
      escapeValue: false
    }
  });

  export default i18n;
import * as React from 'react';
import cryptoRandomString from 'crypto-random-string';
import { TiDelete } from 'react-icons/ti';
import SquareField from './SuareField';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';

import './AddressField.scss';
import { logger } from '../../utils/logger';

export interface IAddressFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  onSelectAddress: (coord: {lat: number; lng: number, description: string}) => any;
}

export default function AddressField ({ onSelectAddress, ...props }: IAddressFieldProps) {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      types: ['address'],
      componentRestrictions: {
        country: ['ca']
      }
    },
    debounce: 300,
  });
  const ref = useOnclickOutside(() => {
    clearSuggestions();
  });

  const handleSelect = ({ description }: any) => () => {
    setValue(description, false);
    clearSuggestions();

    // Get latitude and longitude via utility functions
    getGeocode({ address: description })
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        logger.info('📍 Coordinates: ', { lat, lng });
        onSelectAddress({lat, lng, description});
      })
      .catch((error) => {
        logger.error(error);
      });
  };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li key={place_id} onClick={handleSelect(suggestion)}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });
  const id = cryptoRandomString({ length: 10 });
  return (
    <div className="address-autocomplete-container" ref={ref}>
      <SquareField
        onChange={(e) => setValue(e.target.value)}
        disabled={!ready}
        {...props}
        id={id}
        value={props.value ? props.value : value}
      />
      {
        !props.value && value.length >= 3 &&
        <span onClick={() => {
          setValue('', false);
          clearSuggestions();
        }} className="address-delete-button">
          <TiDelete size="30px" color="lightgray" />
        </span>
      }
      {status === 'OK' && <ul className="address-autocomplete">{renderSuggestions()}</ul>}
    </div>
  );
}
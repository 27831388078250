import {
  configureStore,
  ThunkAction,
  Action,
  getDefaultMiddleware,
  combineReducers,
} from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import appReducer from '../features/appSlice';

// Persistor configuration
const persistConfig = {
  key: 'gorush-app',
  version: 1,
  storage,
};

// Combine redux reducers to one
const rootReducer = combineReducers({
  app: appReducer,
});

// Create persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Disable serializable check with a custom middleware
const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false,
});

// Create and export redux store
export const store = configureStore({
  reducer: persistedReducer,
  middleware: customizedMiddleware,
});

// Create and export persitor
export const persistor = persistStore(store);

// Typescript
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

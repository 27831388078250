export const priceCalculator = (surface: number, service?: Parse.Object) => {
  if(!service){
    return {
      priceCustomer: 0,
      priceWorker: 0
    };
  }

  const fee = service.get('fee');
  const factor = service.get('variablePrice');
  const basePrice: number = service.get('fixedPrice');
  const serviceType = service.get('code');

  let variablePrice = 0;
  if(factor !== undefined && factor !== null && factor > 0){
    variablePrice = surface * factor;
  }
  
  let price = parseFloat((variablePrice + basePrice).toFixed(2));
  
  if (serviceType === 'lawnmover') {
    // TODO
  }
  
  return {
    priceCustomer: price,
    //@ts-ignore
    priceWorker: price - (price * (fee / 100)).toFixed(2)
  };

};
import * as React from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import RoundedButton from '../../components/buttons/RoundedButton';
import { images, useCardBrand } from 'react-card-brand';
import PaymentMethods from '../../components/PaymentMethods';
import Title from '../../components/Title';
import { card, stripeCustomerAccount } from '../../declarations/paymentMethods.d';
import { customModalStyles } from '../../utils/modals';
import { priceCalculator } from '../../utils/priceService';
import StripeCustomer from '../../utils/stripeCustomer';
import Parse, { getPaymentMethods } from '../../utils/parse';
import './PaymentModal.scss';
import Margin from '../../components/common/Margin';

export interface IPaymentModalProps {
  isOpen: boolean;
  toggle: any;
  onConfirm?: (card: card) => any;
  service?: Parse.Object;
  surface: number;
}

export default function PaymentModal ({ isOpen, toggle, onConfirm = () => {}, service, surface  }: IPaymentModalProps) {
  const { t } = useTranslation();
  const { getSvgProps } = useCardBrand();
  const [_editCardsMode, set_editCardsMode] = React.useState(false);
  const [_loading, set_loading] = React.useState(false);
  const [_stripeCustomer, set_stripeCustomer] = React.useState<stripeCustomerAccount>();
  const currentUser = Parse.User.current();
  const cards: card[] = _stripeCustomer?.sources?.data || [];
  const defaultCard = cards.find((c) => c.id === _stripeCustomer?.default_source);

  const fetchPaymentMethods = () => {
    set_loading(true);
    if(currentUser){
      StripeCustomer.me()
      .then((stripeCustomer) => {
        getPaymentMethods(stripeCustomer?.attributes?.idStripe)
        .then((customerAccount: stripeCustomerAccount) => {
          set_stripeCustomer(customerAccount);
        })
        .finally(() => {
          set_loading(false);
          set_editCardsMode(false);
        });
      });
    }
  };

  React.useEffect(() => {
    fetchPaymentMethods();
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={toggle}
      style={customModalStyles}
    >
      <Grid className="payment-modal">
        <Row>
          <Col xs={12} className="d-flex flex-column justify-content-center align-items-center">
            <Title secondary>
              {t(`services.codes.${service?.attributes?.code}`)}
            </Title>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <h2 className="subtitle">{t('request.payment_modal.subtitle')}</h2>
              <p className="price">
                ${priceCalculator(surface, service).priceCustomer}
              </p>
              <RoundedButton
                disabled={!Boolean(defaultCard) || _editCardsMode}
                onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if(defaultCard){
                    onConfirm(defaultCard);
                  }
                }}
              >
                {t('shared.pay')}
              </RoundedButton>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <Margin />
              {
                defaultCard && !_editCardsMode &&
                <span className="d-flex align-items-center">
                  <span className="d-flex align-items-center mr-2 card-info">
                    <svg {...getSvgProps({ type: defaultCard.brand?.toLowerCase(), images, width: '60px', height: '60px' })} />
                    <span style={{ fontSize: '30px' }} className="ml-2">.... {defaultCard.last4}</span>
                  </span>
                  <a style={{ fontSize: '18px' }} onClick={() => set_editCardsMode(true)} href="#">{t('shared.edit')}</a>
                </span>
              }
              {
                (_editCardsMode || !Boolean(defaultCard)) &&
                <PaymentMethods selectable onSelectCard={fetchPaymentMethods} />
              }
            </div>
          </Col>
        </Row>
      </Grid>
    </Modal>
  );
}

import * as React from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { useTranslation } from 'react-i18next';
import { card } from '../../declarations/paymentMethods.d';
import RoundedButton from '../buttons/RoundedButton';
import Margin from '../common/Margin';
import AddPaymentMethod from './AddPaymentMethod';
import List from './List';


export interface IPaymentMethodsProps {
  onSelectCard?: (card: card | null) => any;
  selectable?: boolean;
}

export default function PaymentMethods ({ onSelectCard = () => {}, selectable = false }: IPaymentMethodsProps) {
  const [_addMode, set_addMode] = React.useState(false);
  const [_newCardAdded, set_newCardAdded] = React.useState(0);
  const { t } = useTranslation();

  return (
    <Grid style={{ width: 'auto', maxWidth: '90vw' }}>
      <Row>
        <Col xs={12}>
          <List newCardAdded={_newCardAdded} onSelectCard={onSelectCard} selectable={selectable} />
        </Col>
      </Row>
      <Row className="d-flex justify-content-center">
        <Col xs={12} sm={6} style={{ marginBottom: 10 }} className="d-flex justify-content-center">
          {
            _addMode
              ? <div>
                  <RoundedButton breakable onClick={() => set_addMode(false)}>
                    {t('shared.cancel')}
                  </RoundedButton>
                  <Margin />
                  <div>
                    <AddPaymentMethod onPaymentAdded={() => {set_newCardAdded(_newCardAdded+1); set_addMode(false);}} />
                  </div>
                </div>
              : <RoundedButton breakable onClick={() => set_addMode(true)}>
                {t('payment_methods.add_new_card')}
              </RoundedButton>
          }
        </Col>
        {
          !_addMode &&
          <Col xs={12} sm={6} className="d-flex justify-content-center">
            <RoundedButton secondary onClick={() => onSelectCard(null)}>
              {t('shared.cancel')}
            </RoundedButton>
          </Col>
        }
      </Row>
    </Grid>
  );
}

import * as React from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import RoundedButton from '../../components/buttons/RoundedButton';
import Spinner from '../../components/Spinner';
import Title from '../../components/Title';
import { customModalStyles } from '../../utils/modals';
import Parse from '../../utils/parse';
import EditProfileForm from './EditProfileForm';
import defaultAvatar from '../../images/default-avatar.png';

import './index.scss';
import PaymentMethods from '../../components/PaymentMethods';
import { logger } from '../../utils/logger';
export interface IMyAccountProps {
}

export default function MyAccount (props: IMyAccountProps) {
  const { t } = useTranslation();
  const fileInpurRef: React.RefObject<HTMLInputElement> = React.useRef(null);
  const currentUser = Parse.User.current();
  const [_picture, set_picture] = React.useState<Parse.File>(currentUser?.attributes?.profilePicture);
  const [_loading, set_loading] = React.useState(false);
  const [_modalMode, set_modalMode] = React.useState<undefined | 'edit' | 'payments'>();
  const onClose = () => set_modalMode(undefined);

  const onFilesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    set_loading(true);
    const filesData = e.target.files || [];
    const fileData = filesData[0];
    const file = new Parse.File(fileData.name, fileData);
    file.save()
    .then((fileResult) => {
      set_picture(fileResult);
      currentUser?.set('profilePicture', fileResult);
      currentUser?.save();
    })
    .catch((err) => logger.error(err.message))
    .finally(() => set_loading(false));
  };

  return (
    <Grid className="my-account">
      <Row>
        <Col xs={12}>
          <Title secondary>
            {t('my_account.title')}
          </Title>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="d-flex justify-content-center">
          <Title secondary>
          {currentUser?.attributes?.firstName} {currentUser?.attributes?.lastName}
          </Title>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="d-flex flex-column align-items-center justify-content-center user-picture-container">
          <input ref={fileInpurRef} onChange={onFilesChange} type="file" hidden />
          {
            _loading
            ? (
              <div className="d-flex justify-content-center align-items-center user-picture">
                <Spinner primary />
              </div>
            ) : (
              <>
                <img src={_picture?.url() || defaultAvatar} alt="user-avatar" className="user-picture" loading="lazy" />
                <p onClick={() => fileInpurRef.current?.click()}>
                  {t('my_account.change_picture')}
                </p>
              </>
            )
          }
          {
            Boolean(currentUser?.attributes.phone) &&
            <label>
              {t('my_account.phone_number', { number: currentUser?.attributes.phone })}
            </label>
          }
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="d-flex justify-content-center mt-3">
          <RoundedButton onClick={() => set_modalMode('edit')}>
            {t('shared.edit')}
          </RoundedButton>
        </Col>
        <Col xs={12} className="d-flex justify-content-center mt-3">
          <RoundedButton onClick={() => set_modalMode('payments')}>
            {t('my_account.payment_methods')}
          </RoundedButton>
        </Col>
      </Row>
      <Modal style={{...customModalStyles, overlay: { overflowY: 'scroll' }}} onRequestClose={onClose} isOpen={Boolean(_modalMode)}>
        {
          _modalMode === 'edit' &&
          <EditProfileForm toggle={onClose} onSuccess={onClose} />
        }
        {
          _modalMode === 'payments' &&
          <PaymentMethods onSelectCard={onClose} />
        }
      </Modal>
    </Grid>
  );
}

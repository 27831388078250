import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useOutsideClick } from '../../utils/hooks';
import './index.scss';

export interface ILocalesProps {
  isOpen: boolean;
  toggle: any;
}

export default function Locales ({ isOpen, toggle }: ILocalesProps) {
  const { i18n } = useTranslation();
  const ref = React.useRef(null);
  useOutsideClick(ref, toggle);
  const { data } = i18n.store;
  const classNames = `account-menu ${isOpen ? 'menu-open' : 'menu-close'}`;

  return (
    <div ref={ref} className={classNames}>
      {
        Object.keys(data).map((language) => {
        return (
          <div key={language} className="account-menu-item">
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => i18n.changeLanguage(language, toggle)}
            >
            {
              //@ts-ignore
              data[language].translation.language
            }
            </span>
          </div>
        );
        })
      }
    </div>
  );
}

import * as React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import Parse from '../utils/parse';

export interface IPrivateRouteProps extends RouteProps {
  componentProps?: object;
}

export default function PrivateRoute({
  component,
  componentProps = {},
  ...rest
}: IPrivateRouteProps) {
  const currentUser = Parse.User.current();
  const isConnected = Boolean(currentUser);
  const Component = component;
  return (
    //@ts-ignore
    <Route
      {...rest}
      render={(props) =>
        isConnected ? (
          //@ts-ignore
          <Component {...{ ...props, ...componentProps }} />
        ) : (
          <Redirect to="/signin" />
        )
      }
    />
  );
}

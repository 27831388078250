import * as React from 'react';
import './RoundedButton.scss';

export interface IRoundedButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>  {
  onClick?: any;
  primary?: boolean;
  secondary?: boolean;
  breakable?: boolean;
}

export default function RoundedButton ({ children, onClick, primary = true, secondary = false, breakable = false, ...props }: IRoundedButtonProps) {
  const type = secondary ? 'secondary' : 'primary';
  const classnames = `rounded-button d-flex justify-content-center align-items-center ${type} ${props.className ? props.className : ''} ${props.disabled ? 'disabled' : ''} ${breakable ? 'break-line' : ''}`;
  return (
    <button {...props} className={classnames} onClick={onClick}>
      {children}
    </button>
  );
}

import * as React from 'react';
import Modal from 'react-modal';
import { isTest } from '../../utils/env';
import { customModalStyles } from '../../utils/modals';
import Signin from './Signin';
import Signup from './Signup';

if(!isTest){
  Modal.setAppElement('#root');
}

export interface IAuthModalProps {
  isOpen: boolean;
  toggle: any;
  onSuccess?: () => any;
}

export default function AuthModal ({ isOpen, toggle, onSuccess }: IAuthModalProps) {
  const [_type, set_type] = React.useState<'signin' | 'signup'>('signin');
  const toggleType = () => {
    return _type === 'signin' ? set_type('signup') : set_type('signin');
  };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={toggle}
      style={customModalStyles}
    >
      {
        _type === 'signin' &&
        <Signin modal toggleType={toggleType} onSuccess={onSuccess ? onSuccess : toggle} />
      }
      {
        _type === 'signup' &&
        <Signup modal toggleType={toggleType} onSuccess={onSuccess ? onSuccess : toggle} />
      }
    </Modal>
  );
}

// Uses scss files to get colors in js files instead of current
// https://github.com/facebook/create-react-app/issues/10047#issuecomment-774066608

const colors = {
  primary: '#E95B17',
  secondary: '#0B3B00',
  darkGreen: '#00340A',
  green: '#3CB043'
};

export default colors;

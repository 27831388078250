import * as React from 'react';
import colors from '../styles/variables';

export interface ITitleProps extends React.HTMLAttributes<HTMLHeadingElement> {
  primary?: boolean;
  secondary?: boolean;
}

function Title(props: ITitleProps) {
  const customProps = {...props, style: { ...props.style || {} }};
  if(customProps.primary){
    customProps.style.color = colors.primary;
  }
  if(customProps.secondary){
    customProps.style.color = colors.secondary;
  }
  if(!customProps.style.fontSize){
    customProps.style.fontSize = '40px';
  }
  if(!customProps.style.fontWeight){
    customProps.style.fontWeight = 900;
  }
  if(!customProps.style.lineHeight){
    customProps.style.lineHeight = '35px';
  }
  //@ts-ignore
  delete customProps.primary;
  //@ts-ignore
  delete customProps.secondary;
  return <h1 {...customProps}>{props.children}</h1>;
}

export default Title;

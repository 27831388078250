import { useState, useEffect, useRef, useCallback } from 'react';

export const useForceUpdate = () => {
  const set = useState(0)[1];
  return () => set((s) => s + 1);
};

export function useOutsideClick(ref: React.MutableRefObject<null>, callback: () => any) {
  useEffect(() => {
    function handleClickOutside(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
      //@ts-ignore
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }
    //@ts-ignore
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      //@ts-ignores
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}

export function useStateCallback(initialState: any) {
  const [state, setState] = useState(initialState);
  const cbRef = useRef(null); // mutable ref to store current callback

  const setStateCallback = useCallback((state, cb) => {
    cbRef.current = cb; // store passed callback to ref
    setState(state);
  }, []);

  useEffect(() => {
    // cb.current is `null` on initial render, so we only execute cb on state *updates*
    if (cbRef.current) {
      //@ts-ignore
      cbRef.current(state);
      cbRef.current = null; // reset callback after execution
    }
  }, [state]);

  return [state, setStateCallback];
}

export function useDragging() {
  const [isDragging, setIsDragging] = useState(false);
  const [pos, setPos] = useState({ x: 0, y: 0 });
  const ref = useRef(null);

  function onMouseMove(e: any) {
    if (!isDragging) return;
    setPos({
      //@ts-ignore
      x: e.x - ref.current.offsetWidth / 2,
      //@ts-ignore
      y: e.y - ref.current.offsetHeight / 2,
    });
    e.stopPropagation();
    e.preventDefault();
  }

  function onMouseUp(e: any) {
    setIsDragging(false);
    e.stopPropagation();
    e.preventDefault();
  }

  function onMouseDown(e: any) {
    if (e.button !== 0) return;
    setIsDragging(true);

    setPos({
      //@ts-ignore
      x: e.x - ref.current.offsetWidth / 2,
      //@ts-ignore
      y: e.y - ref.current.offsetHeight / 2,
    });

    e.stopPropagation();
    e.preventDefault();
  }

  // When the element mounts, attach an mousedown listener
  useEffect(() => {
    //@ts-ignore
    ref.current.addEventListener('mousedown', onMouseDown);

    return () => {
      //@ts-ignore
      ref.current.removeEventListener('mousedown', onMouseDown);
    };
  }, [ref.current]);

  // Everytime the isDragging state changes, assign or remove
  // the corresponding mousemove and mouseup handlers
  useEffect(() => {
    if (isDragging) {
      document.addEventListener('mouseup', onMouseUp);
      document.addEventListener('mousemove', onMouseMove);
    } else {
      document.removeEventListener('mouseup', onMouseUp);
      document.removeEventListener('mousemove', onMouseMove);
    }
    return () => {
      document.removeEventListener('mouseup', onMouseUp);
      document.removeEventListener('mousemove', onMouseMove);
    };
  }, [isDragging]);

  return [ref, pos.x, pos.y, isDragging];
}
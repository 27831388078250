import Parse from './parse';

class StripeCustomer extends Parse.Object.extend('StripeCustomer') {
  static apiName = 'StripeCustomer';
  static getAll(opt = { limit: 25 }) {
    const query = new Parse.Query(this.apiName);
    query.limit(opt.limit);
    return query.find();
  }

  static me() {
    const query = new Parse.Query(this.apiName);
    query.equalTo('user', Parse.User.current());
    return query.first();
  }
}

export default StripeCustomer;

import { isDevToolsRunning } from './devtools';
import { isDev, isProd } from './env';

interface options {
  level: 'error' |'warning' | 'info' | 'none';
}

const defaultOptions: options = {
  level: isProd ? 'error' : (isDev ? 'warning' : 'none')
};

export class GorushLogger {
  options: options;

  constructor(opt: options = defaultOptions){
    this.options = { ...defaultOptions, ...opt };
  }

  error(...arg: any){
    //@ts-ignore
    const level =  isDevToolsRunning() ? window.goRush.logLevel : this.options.level;
    if(['info', 'warning', 'error'].includes(level)){
      console.log('🚨 ERROR - ', ...arg);
    }
  } 

  warning(...arg: any){
    //@ts-ignore
    const level =  isDevToolsRunning() ? window.goRush.logLevel : this.options.level;
    if(['info', 'warning'].includes(level)){
      console.log('⚠️ WARNING - ', ...arg);
    }
  }

  info(...arg: any){
    //@ts-ignore
    const level =  isDevToolsRunning() ? window.goRush.logLevel : this.options.level;
    if(level === 'info'){
      console.log('ℹ️ INFO - ', ...arg);
    }
  }
}


export const logger = new GorushLogger();
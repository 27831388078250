import * as React from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';
import Parse, { addPaymentMethod } from '../../utils/parse';
import StripeCustomer from '../../utils/stripeCustomer';
import RoundedButton from '../buttons/RoundedButton';
import Spinner from '../Spinner';
import { logger } from '../../utils/logger';

export interface IAddPaymentMethodProps {
  onPaymentAdded: () => any;
}

export default function AddPaymentMethod ({ onPaymentAdded }: IAddPaymentMethodProps) {
  const stripe = useStripe();
  const elements = useElements();
  const currentUser = Parse.User.current();
  const [_loading, set_loading] = React.useState(false);
  const [_isValid, set_isValid] = React.useState(false);
  const [_stripeCustomerId, set_stripeCustomerId] = React.useState<undefined | string>();
  const { t } = useTranslation();

  React.useEffect(() => {
    if(currentUser){
      StripeCustomer.me()
      .then((stripeCustomer) => {
        set_stripeCustomerId(stripeCustomer?.attributes?.idStripe);
      });
    }
  }, []);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    set_loading(true);
    if (!stripe || !elements || !currentUser) {
      return;
    }
    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      return;
    }

    stripe
    .createToken(cardElement, {
      name: `${currentUser.attributes.firstName} ${currentUser.attributes.lastName}`
    })
  .then((result) => {
    logger.info('Result', result);
    const id = result.token?.id;
    if(_stripeCustomerId && id){
      addPaymentMethod(id, _stripeCustomerId)
      .then(onPaymentAdded)
      .finally(cardElement.clear);
    }
  }).finally(() => set_loading(false));
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <CardElement onChange={(e) => set_isValid(e.complete)} />
      </div>
      <RoundedButton className="mt-3" type="submit" disabled={!stripe || !_isValid || _loading}>
        {
          _loading &&
            <Spinner secondary />
        }
        {t('shared.add_card')}
      </RoundedButton>
    </form>
  );
}

export const urlToBlob = (url: string): Promise<string | undefined> => new Promise((resolve, reject) => {
  try {
    const request = new XMLHttpRequest();
    request.open('GET', url, true);
    request.responseType = 'blob';
    request.onload = function() {
        const reader = new FileReader();
        reader.readAsDataURL(request.response);
        reader.onload = function(e){
            if(e.target){
              return resolve(e.target.result?.toString());
            }
        };
    };
    request.send();
  } catch (error) {
    return reject(error);
  }
});
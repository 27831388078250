import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import Signin from './pages/auth/Signin';
import Signup from './pages/auth/Signup';
import MyAccount from './pages/MyAccount';
import MyRequests from './pages/MyRequests';
import RequestForm from './pages/requests/Form';
import Services from './pages/Services';
import PrivateRoute from './routes/PrivateRoute';

export interface IRouterProps {
}

function Router (props: IRouterProps) {
  return (
    <>
      <Switch>
        <Route exact path="/" component={MyRequests} />
        <Route exact path="/signin" component={Signin} />
        <Route exact path="/signup" component={Signup} />
        <Route exact path='/requests/new' component={RequestForm} />
        <PrivateRoute exact path='/requests/:id' component={RequestForm} componentProps={{ mode: 'edit' }} />
        <PrivateRoute exact path='/my-account' component={MyAccount} />
        <Route exact path="/services" component={Services} />
      </Switch>
    </>
  );
}

export default Router;
import * as React from 'react';
import './Badge.scss';

export interface IBadgeProps {
  count: number;
}

export default function Badge ({ count }: IBadgeProps) {
  return (
    <div className="badge">
      {count}
    </div>
  );
}

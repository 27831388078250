import * as React from 'react';
import './SquareField.scss';
import randomString from 'crypto-random-string';

export interface ISquareFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
}

export default function SquareField ({ label, id, ...props}: ISquareFieldProps) {
  const sharedId = id || randomString({ length: 10 });
  return (
    <div style={{ width: props.style?.width ? props.style.width : 'auto' }} className="square-field">
      {
        label &&
        <label className="mb-4" htmlFor={sharedId}>{label}</label>
      }
      {
        props.type === 'textarea'
        //@ts-ignore
        ? <textarea id={sharedId} {...props} />
        : <input id={sharedId} {...props} />
      }
    </div>
  );
}
import * as React from 'react';
import Badge from './Badge';
import { buildStaticMapUrl } from '../../utils/googleMap';
import Request from '../../utils/request';
import './RequestCard.scss';
import { RouteChildrenProps, withRouter } from 'react-router-dom';
import Title from '../Title';

export interface IRequestCardProps extends RouteChildrenProps {
  request: Request;
  notifications?: string[];
}

function RequestCard ({ request, notifications = [], history }: IRequestCardProps) {
  const [_name, set_name] = React.useState('');
  const serviceName = async () => {
    const service = request.get('service');
    if(service){
      await service.fetch();
      set_name(service.attributes.name);
    }
  };

  React.useEffect(() => {
    serviceName();
  }, []);

  return (
    <div className="request-card" onClick={() => history.push(`/requests/${request.id}`)}>
      <div className="request-image" style={{ backgroundImage: `url(${request.attributes.photo?.url() || buildStaticMapUrl(request)})` }}>
        {
          notifications.length >= 1 &&
          <div style={{ position: 'absolute', top: 25, right: 25 }}>
            <Badge count={notifications.length} />
          </div>
        }
      </div>
      <div className="request-state">
        {request.attributes.state || 'pending'}
      </div>
      <div className="request-content">
        <label>
          {_name}
        </label>
        <h1>${request.attributes.priceCustomer || '0.00'}</h1>
      </div>
    </div>
  );
}

//@ts-ignore
export default withRouter(RequestCard);
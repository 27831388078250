import * as React from 'react';
import { useTranslation } from 'react-i18next';
import RoundedButton from '../../components/buttons/RoundedButton';
import { useForceUpdate } from '../../utils/hooks';
import Modal from 'react-modal';
import Parse from '../../utils/parse';
import Request from '../../utils/request';
import { customModalStyles } from '../../utils/modals';
import { Col, Grid, Row } from 'react-flexbox-grid';

export interface IWorkerStateJobProps {
  request: Request;
  mode: 'edit' | 'create';
  loading: boolean;
  setLoading: (state: boolean) => any;
}

export default function WorkerStateJob ({ request, mode, loading, setLoading  }: IWorkerStateJobProps) {
  const { t } = useTranslation();
  const forceupdate = useForceUpdate();
  const [_modal, set_modal] = React.useState(false);
  const toggleModal = () => set_modal(!_modal);

  if(mode === 'create'){
    return null;
  }

  const { photoStart, photoEnd, videoStart, videoEnd } = request.attributes;
  const isStarted = (Boolean(photoStart) || Boolean(videoStart));
  const isEnded = (Boolean(photoEnd) || Boolean(videoEnd));

  if(!isStarted){
    return null;
  }

  const renderMedia = () => {
    const style = { width: '100%', height: 'auto', maxHeight: '90vh' };
    if(isStarted && !isEnded){
      if(Boolean(photoStart)){
        return <img alt="started-work" src={photoStart.url()} style={style} />;
      }
      if(Boolean(videoStart)){
        return <video controls src={videoStart.url()} style={style} />;
      }
    }
    if(isEnded){
      if(Boolean(photoEnd)){
        return <img alt="ended-work" src={photoEnd.url()} style={style} />;
      }
      if(Boolean(videoEnd)){
        return <video controls src={videoEnd.url()} style={style} />;
      }
    }

    return null;
  };

  return (
    <>
      <RoundedButton onClick={toggleModal}>
        {
          isStarted && !isEnded &&
          t('request.state.show_started')
        }
        {
          isEnded &&
          t('request.state.show_ended')
        }
      </RoundedButton>

      <Modal
        isOpen={_modal}
        onRequestClose={toggleModal}
        style={customModalStyles}
      >
        <Grid>
          <Row>
            <Col xs={12}>
              {renderMedia()}
            </Col>
          </Row>
        </Grid>
      </Modal>
    </>
  );
}

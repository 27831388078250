import { logger } from './logger';

const devToolsKey = 'BSY86Z8BS88S6826S628S282S2BS2SB82SB2B82';

export const startDevTools = () => {
  localStorage.setItem(devToolsKey, 'on');
  return logger.info('Devtools started, reload page !');
};

export const stopDevTools = () => {
  localStorage.removeItem(devToolsKey);
  return logger.info('Devtools stoped, reload page !');
};

export const isDevToolsRunning = () => {
  return localStorage.getItem(devToolsKey) === 'on';
};

export const setDevTools = () => {
  //@ts-ignore
  window.goRush = {
    startDevTools,
    stopDevTools,
    logLevel: 'error'
  };
};
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../app/store';

interface AppState {
  mapLoaded: boolean;
}

const initialState: AppState = {
  mapLoaded: false,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setMapLoadingState: (state, action) => {
      state.mapLoaded = action.payload;
    },
  },
});

export const { setMapLoadingState } = appSlice.actions;

// export const incrementAsync = (amount: number): AppThunk => (dispatch) => {
//   setTimeout(() => {
//     dispatch(incrementByAmount(amount));
//   }, 1000);
// };

export const getAppState = (state: RootState) => state.app;

export default appSlice.reducer;

import colors from '../styles/variables';
import { logger } from './logger';
import Request, { getRequestCenter } from './request';

export interface customShape extends google.maps.Polygon {
  id: number;
  type: 'marker' | 'circle' | 'rectangle' | 'polygon';
  getRadius: () => number;
  getBounds: () => any;
}

type lat = number;
type lng = number;
export interface formatedZone {
  id: number;
  type: 'marker' | 'circle' | 'rectangle' | 'polygon';
  path: [lat, lng];
  surface: number;
}

export const googleMapApi: typeof google = window.google;

export const drawingManagerOptions = googleMapApi ? {
  drawingMode: googleMapApi.maps.drawing.OverlayType.POLYGON,
  drawingControl: true,
  drawingControlOptions: {
    position: googleMapApi.maps.ControlPosition.TOP_CENTER,
    drawingModes: [
      // googleMapApi.maps.drawing.OverlayType.MARKER,
      // googleMapApi.maps.drawing.OverlayType.CIRCLE,
      googleMapApi.maps.drawing.OverlayType.POLYGON,
      // googleMapApi.maps.drawing.OverlayType.POLYLINE,
      // googleMapApi.maps.drawing.OverlayType.RECTANGLE,
    ],
  },
  markerOptions: {
    icon:
      'https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png',
  },
  circleOptions: {
    editable: true,
    zIndex: 1,
    fillColor: colors.secondary
  },
  polygonOptions: {
    editable: true,
    zIndex: 1,
    fillColor: colors.secondary,
    strokeColor: colors.primary,
    strokeWeight: 5
  },
  rectangleOptions: {
    editable: true,
    zIndex: 1,
    fillColor: colors.secondary
  },
} : {};

export const buildStaticMapUrl = (request: Request) => {
  let areas = request?.attributes?.mowing?.areaArray ||
    request?.attributes?.snowing?.areaArray ||
    request?.attributes?.aeration?.areaArray ||
    request?.attributes?.dethatching?.areaArray ||
    [];
  if(!Array.isArray(areas)){
    areas = [areas];
  }
  try {
    const shapes = areas.map((area: any, idx: number) => {
      const polygon = area[idx];
      const firstCoord = polygon[0];
      polygon.push(firstCoord);
      return polygon.join('|');
    }).join('&path=color:0xEB4824|weight:5|');
  
    const width = window.screen.width / 2;
    const size = '600x400';
    const baseurl = 'https://maps.googleapis.com/maps/api/staticmap';
    const center = `center=${getRequestCenter(request)}`;
    const params = `zoom=18&size=${size}&maptype=satellite`;
    const key = `key=${process.env.REACT_APP_GOOGLE_MAP_API}`;
    const url = `${baseurl}?${center}&${params}&path=color:0xEB4824|weight:5|${shapes}&${key}`;
  
    logger.info('Static map generated', { areas, request });
    return url;
  } catch (error) {
    logger.error({error, request, areas});
    return '';
  }
};

export const toGmapPoint = ({ lat, lng }: { lat: number, lng: number }) => {
  return new googleMapApi.maps.LatLng(lat, lng);
};

const rad = (x: number) => {
  return x * Math.PI / 180;
};

export const getDistance = (p1: google.maps.LatLng, p2: google.maps.LatLng) => {
  const R = 6378137; // Earth’s mean radius in meter
  const dLat = rad(p2.lat() - p1.lat());
  const dLong = rad(p2.lng() - p1.lng());
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(p1.lat())) * Math.cos(rad(p2.lat())) *
    Math.sin(dLong / 2) * Math.sin(dLong / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return d; // returns the distance in meter
};

export const isLessThanMeter = (p1: google.maps.LatLng, p2: google.maps.LatLng, distance: number) => {
  return getDistance(p1, p2) <= distance;
};

export const polygonsFromArea = (areaArray: any[], callback: any) => {
  const gmapPolygons: google.maps.Polygon[] = [];
  areaArray.forEach((area: any, idx: number) => {
    const polygon = area[idx];
    const paths = polygon.map((coord: number[]) => ({ lat: coord[0], lng: coord[1] }));
    const gmPolygon = new google.maps.Polygon({
      paths,
      zIndex: 1,
      fillColor: colors.secondary,
    });
    //@ts-ignore
    gmPolygon.type = 'polygon';
    callback(gmPolygon);
    gmapPolygons.push(gmPolygon);
  });

  return gmapPolygons;
};
import BrowserParse from 'parse';
import NodeParse from 'parse/node';
import { isTest } from './env';

const Parse = isTest ? NodeParse : BrowserParse;

Parse.initialize(
  //@ts-ignore
  process.env.REACT_APP_PARSE_APP_ID,
  process.env.REACT_APP_PARSE_JS_KEY,
  process.env.REACT_APP_PARSE_MASTER_KEY
);
//@ts-ignore
Parse.serverURL = process.env.REACT_APP_PARSE_URL;

export default Parse;

export const deleteFile = (file: Parse.File) => {
  return Parse.Cloud.run('deleteFile', { fileUrl: file.url() });
};

export const addPaymentMethod = (id: string, stripeCustomerId: string) => {
  return Parse.Cloud.run('AddNewPaymentMethodCustomerAccount', { token: id, customerStripeId: stripeCustomerId });
};

export const resendVerificationEmail = (userId: string) => {
  return Parse.Cloud.run('resendVerificationEmail', { userId: userId});
};

export const getPaymentMethods = (customerId: string) => {
  return Parse.Cloud.run('RetreivePaymentMethodCustomerAccount', { customerId });
};

export const setDefaultPaymentMethodCustomer = (customerId: string, cardId: string) => {
  return Parse.Cloud.run('SetDefaultPaymentMethodCustomerAccount', { customerId, cardId });
};

export const deletePaymentMethodCustomer = (customerId: string, cardId: string) => {
  return Parse.Cloud.run('DeletePaymentMethodCustomerAccount', { customerId, cardId });
};

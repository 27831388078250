import * as React from 'react';
import { useForceUpdate } from '../utils/hooks';
import { logger } from '../utils/logger';
import Parse from '../utils/parse';
import Margin from './common/Margin';

export interface IDevToolsProps {
}

const style: React.CSSProperties = {
  position: 'absolute',
  width: '300px',
  height: '200px',
  zIndex: 99999,
  backgroundColor: 'lightgray',
  cursor: 'grab',
  padding: '15px'
};

const levels = ['error', 'warning', 'info', 'none'];

export default function DevTools (props: IDevToolsProps) {
  const forceUpdate = useForceUpdate();

  return (
    <div
      draggable
      style={style}
      onDrag={(e) => {
        logger.info(e);
      }} 
    >
      <div>
        <label htmlFor="loglevel">Niveau des logs</label>
        <select
          id="loglevel"
          //@ts-ignore
          value={window.goRush.logLevel}
          onChange={(e) => {
            //@ts-ignore
            window.goRush.logLevel = e.target.value;
            forceUpdate();
          }}
        >
          {
            levels.map((level) => {
              return <option key={level} value={level}>{level}</option>;
            })
          }
        </select>
      </div>
      <Margin />
      <div>
        <label>Utilisateur courant</label>
        <button onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          logger.info(Parse.User.current());
        }}>Log current user</button>
      </div>
    </div>
  );
}

import * as React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import Checkbox from 'react-custom-checkbox';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { useTranslation } from 'react-i18next';
import * as Icon from 'react-icons/fi';
import { RouteChildrenProps } from 'react-router-dom';
import RoundedButton from '../../components/buttons/RoundedButton';
import Margin from '../../components/common/Margin';
import AddressField from '../../components/inputs/AddressField';
import LineField from '../../components/inputs/LineField';
import SquareField from '../../components/inputs/SuareField';
import Spinner from '../../components/Spinner';
import Title from '../../components/Title';
import Photos from '../../components/uploaders/Photos';
import { mapState } from '../../declarations/map';
import colors from '../../styles/variables';
import { urlToBlob } from '../../utils/file';
import { buildStaticMapUrl, formatedZone } from '../../utils/googleMap';
import { useForceUpdate } from '../../utils/hooks';
import { logger } from '../../utils/logger';
import { params } from '../../utils/params';
import { priceCalculator } from '../../utils/priceService';
import Request from '../../utils/request';
import Services from '../../utils/service';
import AuthModal from '../auth/AuthModal';
import Map from './Map';
import PaymentModal from './PaymentModal';
import StateButton from './StateButton';
import { TimeState } from './TimeState';
import WorkerStateJob from './WorkerStateJob';
import Parse, { resendVerificationEmail } from '../../utils/parse';

let request = new Request();

type requestMode = 'create' | 'edit';

interface formValues {
  id: string;
  options: string[];
  carNumberPlate: string;
  carColor: string;
  carModel: string;
  service_id: string;
  photos: Parse.File[];
  [key: string]: any; 
}

export interface IRequestFormProps extends RouteChildrenProps<{ id: string; service_code: string }> {
  mode?: requestMode;
}

export default function RequestForm ({ mode = 'create', match, history }: IRequestFormProps) {
  const service_code = params().service;
  const { t } = useTranslation();
  const [_service, set_service] = React.useState<Parse.Object<Services> | undefined>(undefined);
  const [_zones, set_zones] = React.useState<formatedZone[]>([]);
  const [_surface, set_surface] = React.useState(0);
  const forceupdate = useForceUpdate();
  const [_loading, set_loading] = React.useState(false);
  const [_initialized, set_initialized] = React.useState(false);
  const [_formValues, set_formValues] = React.useState<formValues>({
    id: '',
    options: [],
    carNumberPlate: '',
    carColor: '',
    carModel: '',
    service_id: '',
    remark: '',
    photos: []
  });

  const [_mapState, set_mapState] = React.useState<mapState>({
    defaultCenter: {
      lat: 59.95,
      lng: 30.33
    },
    center: {
      lat: 59.95,
      lng: 30.33,
      description: ''
    },
    mapTypeId: 'satellite',
    isSet: false,
    zoom: 18
  });

  const serviceType = _service?.attributes?.code;
  const mapAreaMode = ['housesnowremoval', 'lawnmower', 'leafpickups', 'carsnowremoval'].includes(serviceType);
  let currentUser = Parse.User.current();
  const [_authModal, set_authModal] = React.useState(false);
  const [_paymentModal, set_paymentModal] = React.useState(false);
  const [_prePaid, set_prePaid] = React.useState(false);

  React.useEffect(() => {
    if(match?.params?.id && match.params.id !== _formValues.id){
      set_loading(true);
      Request.find(match.params.id)
      .then((result) => {
        if(result){
          //@ts-ignore
          request = result;
          //@ts-ignore
          set_formValues({
            options: request.attributes?.remark?.split(', '),
            ...result?.attributes,
            id: result?.id
          });
          Services.findBy('objectId', request.attributes.serviceId)
          .then((result) => set_service(result));
        }
      })
      .finally(() => { set_loading(false); set_initialized(true); });
    }
  }, [match?.params, _formValues.id]);

  React.useEffect(() => {
    if(service_code){
      set_loading(true);
      Services.findBy('code', service_code)
      .then((result) => {
        set_service(result);
        request = new Request();
        request.set('service', result);
        request.set('serviceId', result?.id);
      })
      .finally(() => { set_loading(false); set_initialized(true); });
    }

  }, [service_code]);

  React.useEffect(() => {
    if(_prePaid && _paymentModal){
      set_paymentModal(false);
      createRequest();
    }
  }, [_prePaid]);

  React.useEffect(() => {
    if(mode === 'edit' && params().first_view === 'true'){
      confirmAlert({
        title: t('request.registered.title'),
        message: t('request.registered.body'),
        buttons: [
          {
            label: 'ok',
            className: 'alert-confirm-button',
            onClick: () => {
              const url = new URL(window.location.href);
              const params = new URLSearchParams(url.search);
              params.delete('first_view');
              // reload page is necessary to start time limit timeout
              window.location.href = url.pathname;
            }
          }
        ]
      });
    }
  }, []);

  const onSelectAddress = (coord: { lat: number; lng: number, description: string }) => {
    request.set('address', coord.description);
    // Tonte de gazon
    if(serviceType === 'lawnmower'){
      const mowing = request.attributes.mowing || {};
      request.set('mowing', {...mowing, center: [coord.lat, coord.lng]});
    }
    // Ramassage de feuille
    if(serviceType === 'leafpickups'){
      const leafPicking = request.attributes.leafPicking || {};
      request.set('leafPicking', {...leafPicking, center: [coord.lat, coord.lng]});
    }
    // Déneigement maison
    if(serviceType === 'housesnowremoval'){
      const snowing = request.attributes.snowing || {};
      request.set('snowing', {...snowing, center: [coord.lat, coord.lng]});
    }
    // déneigement de parking
    if(serviceType === 'carsnowremoval'){
      const snowing = request.attributes.snowing || {};
      request.set('snowing', {...snowing, center: [coord.lat, coord.lng]});
    }
    // Aération
    if(serviceType === 'aeration'){
      const aeration = request.attributes.aeration || {};
      request.set('aeration', {...aeration, center: [coord.lat, coord.lng]});
    }
    // Déchaumage
    if(serviceType === 'dethatching'){
      const dethatching = request.attributes.dethatching || {};
      request.set('dethatching', {...dethatching, center: [coord.lat, coord.lng]});
    }
    set_mapState({..._mapState, center: { ...coord }, isSet: true });
  };

  const onValidZones = (zones: formatedZone[]) => {
    set_zones([...zones]);
    var areaArray : { [key:string]:Number[]; } = {};
    zones.forEach(function myFunction(item, index) {
      areaArray[index.toString()] = item.path;
    })

    const totalSurface: number = zones.length >= 1 ? zones.map((z) => {
      if(typeof z.surface == 'string'){
        return parseInt(z.surface);
      }
      return z.surface;
    }).reduce((a, z) => a + z) : 0;
    logger.info({zones});
    set_surface(totalSurface);
  
    // Tonte de gazon
    if(serviceType === 'lawnmower'){
      const mowing = request.attributes.mowing || {};
      request.set('mowing2', {...mowing, areaArray, totalSurface });
    }
    // Ramassage de feuille
    if(serviceType === 'leafpickups'){
      const leafPicking = request.attributes.leafPicking || {};
      request.set('leafPicking', {...leafPicking, areaArray, totalSurface });
    }
    // Déneigement maison
    if(serviceType === 'housesnowremoval'){
      const snowing = request.attributes.snowing || {};
      request.set('snowing', {...snowing, areaArray, totalSurface });
    }
    // Déneigement de parking
    if(serviceType === 'carsnowremoval'){
      const snowing = request.attributes.snowing || {};
      request.set('snowing', {...snowing, areaArray, totalSurface });
    }
    // Aération
    if(serviceType === 'aeration'){
      const aeration = request.attributes.aeration || {};
      request.set('aeration', {...aeration, areaArray, totalSurface });
    }
    // Déchaumage
    if(serviceType === 'dethatching'){
      const dethatching = request.attributes.dethatching || {};
      request.set('dethatching', {...dethatching, areaArray, totalSurface });
    }
  };

  const canCreate = () => {
    const carIdentification = _service?.attributes?.code === 'carsnowremoval' ? (_formValues.carColor.length >= 1 &&
    _formValues.carModel.length >= 1 &&
    _formValues.carNumberPlate.length >= 1) : true;

    return carIdentification &&
    _mapState.isSet &&
    (mapAreaMode ? _zones.length >= 1 : true);
  };

  const createRequest = async () => {
    logger.info('Run', {currentUser, _prePaid});
    const fetchedUser = await Parse.User.current()?.fetch();

    if(!fetchedUser){
      set_authModal(true);
      return null;
    }

    if(!fetchedUser.get('emailVerified')){
      resendVerificationEmail(fetchedUser.id);
      return confirmAlert({
        title: t('messages.email_not_confirmed_title'),
        message: t('messages.email_not_confirmed_body'),
        buttons: [
          {
            label: 'OK',
            className: 'alert-confirm-button',
            onClick: () => {}
          }
        ]
      });
    }

    if(!_prePaid){
      set_paymentModal(true);
      return null;
    } else {
      set_loading(true);
    }


    const photoB64 = await urlToBlob(buildStaticMapUrl(request));
    if(photoB64){
      const file = new Parse.File('photo.jpg', { base64: photoB64 });
      request.set('photo', file);
    }

    if(_service?.attributes?.code === 'carsnowremoval'){
      request.set('carNumberPlate', _formValues.carNumberPlate);
      request.set('carColor', _formValues.carColor);
      request.set('carModel', _formValues.carModel);
    }

    const prices = priceCalculator(_surface, _service);
    request.set('priceCustomer', prices.priceCustomer);
    request.set('priceWorker', prices.priceWorker);
    request.set('remark', _formValues.remark);
    request.set('customer', fetchedUser);
    request.set('customer2', fetchedUser.get('publicUser'));
    request.set('photos', _formValues.photos);
    request.set('state', 'pending');
    request.set('surface', _surface);
    request.set('center', new Parse.GeoPoint([_mapState.center.lat, _mapState.center.lng]));
    logger.info('Before create', { request });
    request.save()
    .then((res: any) => {
      logger.info('After create', { res });
      history.push(`/requests/${res.id}?first_view=true`);
    })
    .finally(() => set_loading(false));
  };

  if (!_initialized){
    return <div style={{ width: '100%', height: '90vh' }} className="d-flex justify-content-center align-items-center">
      <Spinner primary />
    </div>;
  }

  return (
    <>
      {
        _service &&
        <div
          style={{
            width: '100vw',
            height: '300px',
            backgroundColor: 'white',
            backgroundImage: `url(${_service.attributes.coverLarge._url})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover'
          }}
        />
      }
      <Grid fluid style={{ padding: '0 5vw' }}>
      <Row className="service-head">
        <Margin lg />
        <Col md={4} sm={12} xs={12}>
          <Title secondary>
            {t(`services.codes.${_service?.attributes?.code}`)}
          </Title>
        </Col>
        {
          mode === 'edit' &&
          <>
            <Col md={4} sm={6} xs={12} className="d-flex justify-content-end align-items-center mt-1">
              <WorkerStateJob request={request} mode={mode} setLoading={set_loading} loading={_loading} />
            </Col>
            <Col md={4} sm={6} xs={12} className="d-flex justify-content-end align-items-center mt-1">
              <StateButton service={_service} request={request} mode={mode} setLoading={set_loading} loading={_loading} />
            </Col>
          </>
        }
        {
          mode === 'create' &&
          <Col xs={12}>
            <p style={{ color: colors.primary, fontSize: '18px', fontWeight: 900, margin: 0 }}>
              {t('request.catchphrase')}
            </p>
          </Col>
        }
      </Row>

      {
        Boolean(_service?.attributes?.option) 
        && Array.isArray(_service?.attributes?.option) 
        &&
        <Row className="request-options">
          <Margin xl />
          <Col xs={6}>
              <label className="mb-3">
              {t('request.options')}
              </label>
            <Row className={mode === 'edit' ? 'disabled' : ''}>
              {
                _service?.attributes?.option.map((option) => {
                  const selected = _formValues.options.includes(t(`services.options.${option.id}`));
                  return (
                    <Col key={option.id}  xs={12} sm={6}>
                      <Checkbox
                        icon={<Icon.FiCheck color={colors.primary} size={14} />}
                        name={option.id}
                        checked={selected}
                        onChange={() => {
                          if(mode === 'edit'){
                            return null;
                          }

                          let newOptions = [];
                          if(!selected){
                            newOptions = [..._formValues.options, t(`services.options.${option.id}`)];
                          } else {
                            newOptions = _formValues.options.filter((o: string) => o !== t(`services.options.${option.id}`) && o !== undefined);
                          }
                          set_formValues({ ..._formValues, options: [...newOptions], remark: newOptions.join(', ')});
                        }}
                        borderColor={colors.secondary}
                        style={{ cursor: 'pointer' }}
                        labelStyle={{ marginLeft: 5, marginRight: 10, userSelect: 'none', color: 'black', fontWeight: 500 }}
                        label={t(`services.options.${option.id}`)}
                      />
                    </Col>
                  );
                })
              }
              <Col xs={12} sm={6}>
                <Checkbox
                  icon={<Icon.FiCheck color={colors.primary} size={14} />}
                  name={'other'}
                  checked={_formValues.options.includes(t('services.options.other'))}
                  onChange={() => {
                    if(!_formValues.options.includes(t('services.options.other'))){
                      return set_formValues({ ..._formValues, options: [..._formValues.options, t('services.options.other')]});
                    }

                    set_formValues({ ..._formValues, options: [..._formValues.options.filter((o: string) => o !== t('services.options.other'))]});
                  }}
                  borderColor={colors.secondary}
                  style={{ cursor: 'pointer' }}
                labelStyle={{ marginLeft: 5, marginRight: 10, userSelect: 'none', color: 'black', fontWeight: 500 }}
                label={t('services.options.other')}
              />
              </Col>
            </Row>
          </Col>
        </Row>
      }
      {
        _service?.attributes?.code === 'carsnowremoval' &&
        <Row className="car-identification">
          <Margin xl />
          <Col xs={12}>
            <label>
              {t('request.car_identification')}
            </label>
            <LineField
              required
              className="field-md"
              disabled={mode === 'edit'}
              placeholder={t('request.car_number_plate')}
              name="carNumberPlate"
              value={_formValues.carNumberPlate}
              onChange={(e) => set_formValues({ ..._formValues, carNumberPlate: e.target.value })}
            />
            <LineField
              required
              className="field-md"
              disabled={mode === 'edit'}
              placeholder={t('request.car_color')}
              name="carColor"
              value={_formValues.carColor}
              onChange={(e) => set_formValues({ ..._formValues, carColor: e.target.value })}
            />
            <LineField
              required
              className="field-md"
              disabled={mode === 'edit'}
              placeholder={t('request.car_model')}
              name="carModel"
              value={_formValues.carModel}
              onChange={(e) => set_formValues({ ..._formValues, carModel: e.target.value })}
            />
          </Col>
        </Row>
      }
      
      <Row className="address-field-container">
        <Margin xl />
        <Col xs={12}>
          <AddressField
            className="field-md"
            disabled={mode === 'edit'}
            placeholder={_formValues.address}
            onSelectAddress={onSelectAddress}
            label={t('request.address_label')}
            value={mode === 'edit' ? _formValues.address : undefined}
          />
        </Col>
      </Row>

      {
        mode === 'edit' &&
        <Row>
          <Col xs={12} md={8} lg={6}>
            <img className="mt-5" alt="request-areas" src={request.attributes.photo?.url() || buildStaticMapUrl(request)} width="100%" height="auto"/>
          </Col>
        </Row>
      }
      
      {
        _mapState.isSet && mode === 'create' &&
        <Row className="request-location">
          <Margin xl />
          <Col xs={12} md={8} lg={6}>
            <label className="mb-3">
              {t('request.location_label')}
            </label>
            <Map
              mapState={_mapState}
              onValid={onValidZones}
              mapAreaMode={mapAreaMode}
              serviceType={serviceType}
            />
          </Col>
        </Row>
      }
      {
        _mapState.isSet && mode === 'create' &&
        <Row className="request-photos">
          <Margin xl />
          <Col xs={12}>
            <Photos
              onPhotosUploaded={(photos) => set_formValues({..._formValues, photos: [...photos]})}
            />
          </Col>
        </Row>
      }
      {
        mode === 'edit' && Boolean(request?.attributes?.photos) && request?.attributes?.photos.length >= 1 &&
        <Row className="request-photos" style={{ maxWidth: '500px' }}>
          <Margin xl />
          <Col xs={12}>
            <label>{t('request.photos_label')}</label>
            <Margin/>
          </Col>
          {
            request.attributes.photos.map((photo: Parse.File) => {
              return (
                <Col xs={12} md={6} key={photo.name()}>
                  <img alt={photo.name()} src={photo.url()} width="200px" height="auto" />
                </Col>
              );
            })
          }
        </Row>
      }
      <Row className="request-detail">
        <Margin xl />
        <Col xs={12}>
          <SquareField
            style={{ maxWidth: '100%' }}
            className="field-md"
            type="textarea"
            multiple
            required
            disabled={mode === 'edit'}
            placeholder={t('request.details_placeholder')}
            name="remark"
            value={_formValues.remark}
            label={t('request.details')}
            onChange={(e) => set_formValues({ ..._formValues, remark: e.target.value })}
          />
        </Col>
      </Row>
      {
        mode === 'create' &&
        <div className="request-action">
          <Margin xl />
          <RoundedButton
            disabled={!canCreate() || _loading}
            onClick={() => createRequest()}
          >
            {
              _loading &&
              <Spinner secondary />
            }
            {t('request.get_a_quote')}
          </RoundedButton>
        </div>
      }
      {
        mode === 'edit' && ['accepted', 'pending'].includes(request.attributes.state) &&
        <Row className="request-timestate">
          <Col xs={12}>
            <TimeState request={request} />
          </Col>
        </Row>
      }
      <Margin lg/>
    </Grid>
    {
      !currentUser &&
      <AuthModal
        isOpen={_authModal}
        toggle={() => set_authModal(!_authModal)}
        onSuccess={() => {
          set_authModal(false);
          currentUser = Parse.User.current();
          createRequest();
        }}
      />
    }
    {
      mode === 'create' &&
      <PaymentModal
        isOpen={_paymentModal}
        service={_service}
        surface={_surface}
        toggle={() => set_paymentModal(!_paymentModal)}
        onConfirm={(card) => {
          console.log(card);
          if(Boolean(card.id)){
            set_prePaid(true);
          }
        }}
      />
    }
    </>
  );
}

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Title from '../../components/Title';
import Services from '../../utils/service';
import './Card.scss';

interface Props {
  service: Parse.Object<Services>;
  history: any;
}

function Card({ history, service }: Props) {
  const { t } = useTranslation();
  const link = () => {
    history.push(`/requests/new?service=${service.attributes.code}`);
  };
  return (
    <div className="gr-service-card mb-3">
      <div style={{ backgroundImage: `url(${service.attributes.cover._url})` }} className="gr-service-picture">
        <img alt={service.attributes.code} src={service.attributes.icon._url} className="gr-service-icon" />
      </div>
      <div className="gr-service-bottom d-flex flex-column justify-content-center align-items-center mt-5">
        <Title primary>
          {t(`services.codes.${service.attributes.code}`)}
        </Title>
        <p className="p-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        <span role="button" onClick={link} className="gr-service-action d-flex justify-content-center align-items-center">
          {t('services.card.action')}
        </span>
      </div>
    </div>
  );
}

export default Card;

import * as React from 'react';

export interface IMarginProps {
  height?: string | number;
  sm?: boolean;
  md?: boolean;
  lg?: boolean;
  xl?: boolean;
}

export default function Margin ({ height, sm = false, md = false, lg = false, xl = false }: IMarginProps) {
  let h = height ? height : '20px';
  if(sm){
    h = '10px';
  }
  if(md){
    h = '20px';
  }
  if(lg){
    h = '50px';
  }
  if(xl){
    h = '80px';
  }
  return <div style={{ height: h, width: '100%' }} />;
}

import * as React from 'react';
import logoSecondary from '../images/logo-white.png';
import logoPrimary from '../images/logo.png';

export interface ILogoProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  primary?: boolean;
  secondary?: boolean;
  width?: string;
  height?: string;
}

function Logo({ primary, secondary, ...props }: ILogoProps) {
  const customProps = { ...props };
  if(!props.width){
    customProps.width = '150px';
  }
  if(!props.height){
    customProps.height = '150px';
  }
  let logo = logoPrimary;
  if(secondary){
    logo = logoSecondary;
  }
  return <img {...props} alt="gorush-logo" src={logo} />;
}

export default Logo;

import * as React from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { useTranslation } from 'react-i18next';
import { RouteChildrenProps } from 'react-router-dom';
import Spinner from '../../components/Spinner';
import Title from '../../components/Title';
import Service from '../../utils/service';
import Card from './Card';

export interface IServicesProps extends RouteChildrenProps {
}

function Services ({ history }: IServicesProps) {
  const { t } = useTranslation();
  const [_services, set_services] = React.useState<Parse.Object<Service>[]>([]);
  const [_loading, set_loading] = React.useState(true);
  React.useEffect(() => {
    Service.getAll()
    .then((services) => set_services([...services]))
    .finally(() => set_loading(false));
  }, []);

  return (
    <Grid>
      <Row>
        <Col xsOffset={1} xs={11} smOffset={0} sm={12}>
          <Title secondary>
            {t('services.title')}
          </Title>
        </Col>
      </Row>
      <Row>
        {
          _loading &&
          <div style={{ width: '100%', height: '50vh' }} className="d-flex justify-content-center align-items-center">
            <Spinner primary />
          </div>
        }
        {
          _services.map((service) => {
            return (
              <Col key={service.id} xsOffset={1} xs={11} smOffset={0} sm={6} mdOffset={0} md={4}>
                <Card service={service} history={history} />
              </Col>
            );
          })
        }
      </Row>
    </Grid>
  );
}

export default Services;
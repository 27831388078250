import * as React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { useTranslation } from 'react-i18next';
import RoundedButton from '../../components/buttons/RoundedButton';
import Margin from '../../components/common/Margin';
import SquareField from '../../components/inputs/SuareField';
import Spinner from '../../components/Spinner';
import Title from '../../components/Title';
import { useForceUpdate } from '../../utils/hooks';
import { cleanObject } from '../../utils/params';
import Parse from '../../utils/parse';

export interface IEditProfileFormProps {
  toggle: () => any;
  onSuccess: () => any
}

export default function EditProfileForm ({ toggle, onSuccess }: IEditProfileFormProps) {
  const { t } = useTranslation();
  const forceUpdate = useForceUpdate();
  const [_loading, set_loading] = React.useState(false);
  const currentUser = Parse.User.current();
  const userAttr = currentUser?.attributes;
  const edit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if(currentUser){
      set_loading(true);
    //@ts-ignore
    const form = new FormData(e.target);
    const email = form.get('email');
    const firstName = form.get('firstName');
    const lastName = form.get('lastName');
    const phone = form.get('phone');
    const password = form.get('password');
    const attrs = cleanObject({ email, firstName, lastName, phone, password });
    Object.keys(attrs).forEach((key) => {
      currentUser.set(key, attrs[key]);
    });
    
    currentUser.save()
    .then(() => { 
      forceUpdate();
      onSuccess();
      currentUser.fetch();
    })
    .catch((error) => {
      confirmAlert({
        title: t('shared.error'),
        message: error.message,
        buttons: [
          {
            label: 'Ok',
            onClick: () => {},
            className: 'alert-confirm-button'
          }
        ]
      });
    })
    .finally(() => set_loading(false));
    }
  };
  return (
    <Grid style={{ width: '500px', maxWidth: '90vw', paddingRight: '50px' }}>
      <form onSubmit={edit} className="d-flex flex-column">
        <Row>
          <Col xs={12} className="d-flex justify-content-center">
            <Title secondary>{t('my_account.edit_title')}</Title>
          </Col>
          <Margin />
          <Col xs={12} className="d-flex justify-content-center">
            <SquareField style={{ width: '100%' }} defaultValue={userAttr?.firstName} name="firstName" placeholder={t('shared.firstname')} label={t('shared.firstname')} />
          </Col>
          <Margin />
          <Col xs={12} className="d-flex justify-content-center">
            <SquareField style={{ width: '100%' }} defaultValue={userAttr?.lastName} name="lastName" placeholder={t('shared.lastname')} label={t('shared.lastname')} />
          </Col>
          <Margin />
          <Col xs={12} className="d-flex justify-content-center">
            <SquareField style={{ width: '100%' }} defaultValue={userAttr?.username} name="email" placeholder={t('shared.email')} label={t('shared.email')} />
          </Col>
          <Margin />
          <Col xs={12} className="d-flex justify-content-center">
            <SquareField style={{ width: '100%' }} type="password" name="password" placeholder={t('shared.password')} label={t('shared.password')} />
          </Col>
          <Margin />
          <Col xs={12} className="d-flex justify-content-center">
            <SquareField style={{ width: '100%' }}  defaultValue={userAttr?.phone}name="phone" placeholder={t('shared.phone')} label={t('shared.phone')} />
          </Col>
          <Margin />
        </Row>
        <Row>          
          <Col xs={12} sm={6} style={{ marginBottom: 10 }} className="d-flex justify-content-center">
            <RoundedButton type="submit">
              {
                _loading &&
                <Spinner secondary size="18px" />
              }
              {t('shared.update')}
            </RoundedButton>
          </Col>
          <Col xs={12} sm={6} className="d-flex justify-content-center">
            <RoundedButton secondary type="button" onClick={toggle}>
              {t('shared.cancel')}
            </RoundedButton>
          </Col>
        </Row>
      </form>
    </Grid>
  );
}

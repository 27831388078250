import Parse from './parse';

class Request extends Parse.Object.extend('Request') {
  static apiName = 'Request';
  static getAll(opt = { limit: 25 }) {
    const query = new Parse.Query(this.apiName);
    query.limit(opt.limit);
    return query.find();
  }

  static find(id: string | number) {
    const query = new Parse.Query(this.apiName);
    query.equalTo('objectId', id);
    return query.first();
  }

  static where(query: {[key: string]: any}) {
    const q = new Parse.Query(this.apiName);
    Object.keys(query).forEach((key) => {
      q.equalTo(key, query[key]);
    });
    return q.findAll();
  }
}

export default Request;

export const getRequestCenter = (request: Request) => {
  const attrKeys = Object.keys(request.attributes);
  let key;
  if(attrKeys.includes('mowing')){
    key = 'mowing';
  }
  if(attrKeys.includes('snowing')){
    key = 'snowing';      
  }
  if(attrKeys.includes('leafPicking')){
    key = 'leafPicking';
  }
  if(key){
    return request.attributes[key].center;
  }

  return request.attributes.address;
};
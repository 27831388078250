export type env = 'development' | 'staging' | 'production' | 'test';
//@ts-ignore
export const environment: env = process.env.REACT_APP_ENV || 'development';
export const isDev            = environment === 'development';
export const isStaging        = environment === 'staging';
export const isProd           = environment === 'production';
export const isTest           = process.env.NODE_ENV === 'test';

export const frontendUrls = {
  development: '/',
  staging: 'https://gorush.ca',
  production: 'https://gorush.ca',
};

//@ts-ignore
export const frontendUrl: string = frontendUrls[environment];
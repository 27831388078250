import * as React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { Link, Redirect } from 'react-router-dom';
import RoundedButton from '../../components/buttons/RoundedButton';
import Margin from '../../components/common/Margin';
import SquareField from '../../components/inputs/SuareField';
import Logo from '../../components/Logo';
import Spinner from '../../components/Spinner';
import Title from '../../components/Title';

import { useForceUpdate } from '../../utils/hooks';
import Parse from '../../utils/parse';

export interface ISigninProps {
  modal?: boolean;
  toggleType?: () => any;
  onSuccess?: () => any;
}

export default function Signin ({ modal = false, toggleType = () => {}, onSuccess = () => {} }: ISigninProps) {
  const [_loading, set_loading] = React.useState(false);
  const forceUpdate = useForceUpdate();
  const { t } = useTranslation();
  const currentUser = Parse.User.current();
  const isConnected = Boolean(currentUser);
  const signin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    set_loading(true);
    //@ts-ignore
    const form = new FormData(e.target);
    const email = form.get('email');
    const password = form.get('password');
    if(typeof password === 'string' && typeof email === 'string'){
      Parse.User.logIn(email, password)
      .then(() => {
        forceUpdate();
        onSuccess();
      })
      .catch((error) => {
        confirmAlert({
          title: t('shared.error'),
          message: error.message,
          buttons: [
            {
              label: 'Ok',
              className: 'alert-confirm-button',
              onClick: () => {}
            }
          ]
        });
      })
      .finally(() => set_loading(false));
    }
  };

  if(isConnected && !modal){
    return <Redirect to="/" />;
  }

  return (
    <form style={{ height: modal ? 'auto' : '90vh' }} onSubmit={signin} className="d-flex flex-column align-items-center justify-content-center">
      <div>
        <Logo width="114px" />
      </div>
      <div>
        <Title secondary>{t('signin.title')}</Title>
      </div>
      <Margin lg />
      <div>
        <SquareField name="email" placeholder={t('signin.email')} />
      </div>
      <Margin />
      <div>
        <SquareField type="password" name="password" placeholder={t('shared.password')} />
      </div>
      <Margin xl />
      <div>
        <RoundedButton type="submit">
          {
            _loading &&
            <Spinner size="18px" secondary />
          }
          {t('signin.action')}
        </RoundedButton>
      </div>
      <div>
        <p className="info-text">
          {t('signin.new_user')}{' '}
          {
            modal
            ? <a onClick={(e) => { e.preventDefault(); toggleType(); }} href="/signup">{t('signin.create_account')}</a>
            : <Link to="/signup">{t('signin.create_account')}</Link>
          }
        </p>
      </div>
    </form>
  );
}

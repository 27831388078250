import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import './App.scss';
import Navbar from './components/Navbar';
import Router from './Router';
import DevTools from './components/DevTools';
import { isDevToolsRunning, setDevTools } from './utils/devtools';

setDevTools();

function App() {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API || '');

  return (
    <Elements stripe={stripePromise}>
      {
        isDevToolsRunning() && <DevTools />
      }
      <Navbar />
      <Router />
    </Elements>
  );
}

export default App;
